import { format } from 'date-fns';
import {
  useCalcCRLift as getCalcCRLift,
  useCalcCR as getCalcCR,
} from '@pipedataai/storybook';

import {
  CampaignItemStatus,
  CampaignListItem,
} from 'src/store/services/create-campaign/types';

type CampaignTableData = {
  id: number;
  campaignTitle: string;
  distributionType: number;
  crLift: string;
  visitors: number;
  cr: number;
  leads: number;
  avg: number;
  bounce: string;
  createdAt: string;
  status: CampaignItemStatus;
  campaignSegments?: string;
};

const checkLargeCampaignName = (name: string): string => {
  const underscoreCount = (name.match(/_/g) || []).length;

  if (underscoreCount < 3) return name;
  return name
    .split('_')
    .map((part) => part.replace(/([a-z])([A-Z])/g, '$1 $2'))
    .join(' ');
};

const useDataTransform = (campaigns: CampaignListItem[] | undefined) => {
  const campaignsData: CampaignTableData[] = [];
  if (campaigns) {
    campaigns.map((item) => {
      campaignsData.push({
        id: item.id,
        campaignTitle: checkLargeCampaignName(item.name),
        distributionType: item.distribution_type,
        crLift:
          item.distribution_type < 100
            ? getCalcCRLift({
                full_visitors: item.full_visitors,
                experimental_visitors: item.experimental_visitors,
                full_leads: item.full_leads,
                experimental_leads: item.experimental_leads,
                detailLine: true,
              })
            : '-',
        visitors: item.full_visitors + item.experimental_visitors,
        cr: getCalcCR({
          visitors: item.full_visitors + item.experimental_visitors,
          leads: item.full_leads + item.experimental_leads,
        }),
        leads: item.full_leads + item.experimental_leads,
        avg: item.avg_session,
        bounce: item.bounce ? `${item.bounce}%` : '-',
        createdAt: format(Date.parse(item.created_at), 'MM/dd/yy'),
        status: item.status,
      });
    });
  }

  return {
    campaignsData,
  };
};

export default useDataTransform;
