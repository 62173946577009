import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCampaignAPI } from '../services/create-campaign/endpoints/create-campaign';
import {
  GoogleAds,
  GoogleAdsData,
  IElement,
  CampaignFilters,
  CampaignItemStatus,
} from '../services/create-campaign/types';
import { adsTransform, elementTransform } from '../hooks';
import { authAPI } from '../services/auth/endpoints/auth';
import { openAiAPI } from '../services/open-ai-generation/endpoints/open-ai-generation';

const storageFilters = localStorage.getItem('detail_filters');

interface CreateCampaign {
  campaignID?: string;
  campaignName: string;
  segmentID?: string;
  hypothesis_description?: string;
  siteUrl: string;
  googleAdsAccount: string;
  googleAdsAccountName: string;
  googleAdsCampaing: string;
  googleAdsCampaingName: string;
  elements: IElement[];
  domains: string[];
  googleAds: GoogleAdsData[];
  googleAdsURLs: string[];
  distributionType: number;
  actionsID: string[];
  isDeletionDone: boolean;
  filters: CampaignFilters;
  currentAds: number;
  totalAds: number;
  generationType: string;
  status: CampaignItemStatus;
}

const initialState: CreateCampaign = {
  campaignID: '',
  campaignName: '',
  segmentID: '',
  hypothesis_description: '',
  siteUrl: '',
  googleAdsAccount: '',
  googleAdsAccountName: '',
  googleAdsCampaing: '',
  googleAdsCampaingName: '',
  elements: [],
  domains: [],
  googleAds: [],
  googleAdsURLs: [],
  distributionType: 50,
  actionsID: [],
  isDeletionDone: false,
  filters: storageFilters ? JSON.parse(storageFilters) : {},
  currentAds: 0,
  totalAds: 0,
  generationType: '',
  status: CampaignItemStatus.Draft,
};

const campaignSlice = createSlice({
  name: 'campaignSlice',
  initialState,
  reducers: {
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.campaignName = action.payload;
      return state;
    },
    setSegmentID: (state, action: PayloadAction<string>) => {
      state.segmentID = action.payload;
      return state;
    },
    setCampaignHypothesis: (state, action: PayloadAction<string>) => {
      state.hypothesis_description = action.payload;
      return state;
    },
    setCampaignSiteUrl: (state, action: PayloadAction<string>) => {
      state.siteUrl = action.payload;
      return state;
    },
    setSelectedGoogleAds: (state, action: PayloadAction<GoogleAds>) => {
      state.googleAdsAccount = action.payload.value;
      state.googleAdsAccountName = action.payload.label;
      return state;
    },
    setSelectedGoogleAdsCampaign: (state, action: PayloadAction<GoogleAds>) => {
      state.googleAdsCampaing = action.payload.value;
      state.googleAdsCampaingName = action.payload.label;
      return state;
    },
    setSelectedElements: (state, action: PayloadAction<IElement[]>) => {
      if (state.googleAds) {
        state.googleAds = state.googleAds.map((item) => ({
          ...item,
          elements: [
            ...(item.elements || []),
            ...(item.elements
              ? action.payload.filter(
                  (itemEl) =>
                    !item.elements?.find(
                      (el: any) =>
                        itemEl.path === el.path && itemEl.name === el.name
                    )
                )
              : []),
          ],
        }));

        state.googleAds = state.googleAds.map((item) => ({
          ...item,
          elements: [
            ...(item.elements
              ? item.elements.map((e) => ({
                  ...e,
                  name:
                    action.payload.find(
                      (payloadEl) => payloadEl.path === e.path
                    )?.name ?? e.name,
                }))
              : []),
          ],
        }));
      }
      state.elements = action.payload;
      return state;
    },
    setDeleteElementById: (state, action: PayloadAction<string>) => {
      state.elements.forEach((e) => {
        if (e.id === action.payload) {
          e.deletion = true;
        }
      });

      if (state.googleAds) {
        state.googleAds.forEach((item) => {
          (item.elements ?? []).forEach((e) => {
            if (e.id === action.payload) {
              e.deletion = true;
            }
          });
        });
      }
      state.isDeletionDone = true;

      return state;
    },
    setIsDeletionDone: (state) => {
      state.isDeletionDone = false;
    },
    setDeleteElementByPath: (state, action: PayloadAction<string>) => {
      state.elements = state.elements.filter((e) => e.path !== action.payload);
      if (state.googleAds) {
        state.googleAds = state.googleAds.map((item) => ({
          ...item,
          elements:
            item.elements?.filter((e) => e.path !== action.payload) ?? [],
        }));
      }

      return state;
    },
    setGoogleAdsReset: (state, action: PayloadAction<number>) => {
      const payload = action.payload;
      const googleADS = state.googleAds.find(
        (i) => String(i.id) === String(payload)
      );

      if (googleADS?.elements) {
        googleADS.elements = state.elements;
      }

      if (googleADS) {
        googleADS.isPersonalized = true;
      }
      return state;
    },
    setAdsElements: (state, action: PayloadAction<ChangeAdsElement>) => {
      const payload = action.payload;

      const googleADS = state.googleAds.find(
        (i) => String(i.id) === String(payload.adsID)
      );

      if (googleADS?.elements) {
        googleADS.elements[payload.elementIndex] = {
          ...googleADS.elements[payload.elementIndex],
          personalized_value:
            payload.newElementValue !== ''
              ? payload.newElementValue
              : '' ||
                googleADS.elements[payload.elementIndex].personalized_value ||
                googleADS.elements[payload.elementIndex].default_value,
        };
        googleADS.isPersonalized = true;
      }
      return state;
    },
    setDestinationUrl: (state, action: PayloadAction<ChangeAdsElement>) => {
      const payload = action.payload;

      const googleADS = state.googleAds.find(
        (i) => String(i.id) === String(payload.adsID)
      );

      if (googleADS?.elements) {
        googleADS.elements[payload.elementIndex] = {
          ...googleADS.elements[payload.elementIndex],
          destination_url: payload.destination_url,
        };
        googleADS.isPersonalized = true;
      }
      return state;
    },
    setCopyToEmptyAd: (state, action: PayloadAction<number>) => {
      const currentAdElements = state.googleAds.find(
        (ad) => ad.id === `${action.payload}`
      )?.elements;

      if (currentAdElements) {
        state.googleAds = state.googleAds.map((ad) => {
          const findPersonalize = ad.elements?.find(
            (el) => el.destination_url || el.personalized_value
          );
          if (findPersonalize) {
            return ad;
          } else {
            return {
              ...ad,
              elements: currentAdElements,
              isPersonalized: true,
            };
          }
        });
      }
    },
    setCopyToAllAd: (state, action: PayloadAction<number>) => {
      const currentAdElements = state.googleAds.find(
        (ad) => ad.id === `${action.payload}`
      )?.elements;

      if (currentAdElements) {
        state.googleAds = state.googleAds.map((ad) => {
          return {
            ...ad,
            elements: currentAdElements,
            isPersonalized: true,
          };
        });
      }
    },
    setDistributionType: (state, action: PayloadAction<number>) => {
      state.distributionType = action.payload;
    },
    setCampaignId: (state, action: PayloadAction<string>) => {
      state.campaignID = action.payload;
    },
    setActionsID: (state, action: PayloadAction<string[]>) => {
      state.actionsID = action.payload;
    },
    setClearElements: (state) => {
      state.elements = [];
    },
    setFilters: (state, action: PayloadAction<CampaignFilters>) => {
      localStorage.setItem(
        'detail_filters',
        JSON.stringify({
          ...state.filters,
          ...action.payload,
        })
      );
      state.filters = { ...state.filters, ...action.payload };
    },
    setClearFilters: (state) => {
      const filters = {
        startDate: state.filters.startDate,
        endDate: state.filters.endDate,
      };
      localStorage.setItem('detail_filters', JSON.stringify({ ...filters }));
      state.filters = { ...filters };
    },
    setGoogleAdsUrls: (state, action: PayloadAction<string[]>) => {
      state.googleAdsURLs = action.payload;
      return state;
    },
    setClearCampaignData: (state) => {
      const newState = { ...state };
      localStorage.removeItem('detail_filters');
      const filters = { ...newState.filters };

      state = { ...initialState };
      state.filters = filters;
      return state;
    },
    setCurrentAds: (state, action: PayloadAction<number>) => {
      state.currentAds = action.payload;
      return state;
    },
    setTotalAds: (state, action: PayloadAction<number>) => {
      state.totalAds = action.payload;
      return state;
    },
    setGenerationType: (state, action: PayloadAction<string>) => {
      state.generationType = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        createCampaignAPI.endpoints.getCampaignDomainsValue.matchFulfilled,
        (state, action) => {
          state.domains = action.payload.data;
        }
      )
      .addMatcher(
        createCampaignAPI.endpoints.createCampaign.matchFulfilled,
        (state, action) => {
          state.campaignID = action.payload.data.id;
        }
      )
      .addMatcher(
        createCampaignAPI.endpoints.publishCampaign.matchFulfilled,
        (state, action) => {
          state.campaignID = action.payload.data;
        }
      )
      .addMatcher(
        createCampaignAPI.endpoints.getCampainData.matchFulfilled,
        (state, action) => {
          const payload = action.payload;
          state.campaignName = payload.data.campaign.name;
          state.campaignID = payload.data.campaign.id;
          state.hypothesis_description =
            payload.data.campaign.hypothesis_description;
          state.segmentID = payload.data.campaign.segment_id;
          state.siteUrl = payload.data.campaign.page_url;
          state.googleAdsAccount =
            action.payload.data.campaign.googleCampaign.google_customer_id;
          state.googleAdsCampaing =
            action.payload.data.campaign.google_campaign_id;
          state.elements = elementTransform(action.payload.data.elements).sort(
            (a, b) => (a.id && b.id && a.id > b.id ? 1 : -1)
          );
          state.googleAds = adsTransform(
            state.googleAds,
            action.payload.data.ads,
            state.elements.sort((a, b) =>
              a.id && b.id && a.id > b.id ? 1 : -1
            )
          );
          state.distributionType = payload.data.campaign.distribution_type;
          state.actionsID = payload.data.campaign.conversion_actions.map(
            (action) => action.conversion_action_id
          );
          state.status = payload.data.campaign.status;
        }
      )
      .addMatcher(authAPI.endpoints.logoutQuery.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state) => {
        state = initialState;
        return state;
      })
      .addMatcher(
        createCampaignAPI.endpoints.getGoogleAdsCampaign.matchFulfilled,
        (state, action) => {
          if (state.googleAdsCampaing) {
            state.googleAdsURLs =
              action.payload.data
                .find((campaign) => campaign.id === state.googleAdsCampaing)
                ?.extended_urls.map((url) => url.url) || [];
          }
        }
      )
      .addMatcher(
        openAiAPI.endpoints.loadGenerationProcess.matchFulfilled,
        (state, action) => {
          const payload = action.payload;
          state.googleAds = state.googleAds.map((ad) => {
            const payloadElements = payload.items.find(
              (item) => item.google_ad_id == ad.id
            )?.elements;
            if (payloadElements) {
              const updatedElements =
                ad.elements?.map((element) => {
                  const foundElement = payloadElements?.find(
                    (payloadElement) =>
                      payloadElement.personalized_element_id === element.id
                  );
                  if (foundElement) {
                    return {
                      ...element,
                      personalized_value: foundElement.personalized_value_gpt,
                    };
                  }
                  return element;
                }) || ad.elements;

              return {
                ...ad,
                elements: updatedElements,
                isPersonalized: true,
              };
            } else {
              return ad;
            }
          });

          if (payload.task) {
            if (payload.task.complete_dt !== null) {
              state.totalAds = 0;
              state.currentAds = 0;
              return state;
            }

            state.totalAds = payload.task.ads_cnt;
            state.currentAds = payload.task.complete_cnt;
          }

          return state;
        }
      )
      .addMatcher(
        openAiAPI.endpoints.stopGeneration.matchFulfilled,
        (state) => {
          state.totalAds = 0;
          state.currentAds = 0;
          return state;
        }
      )
      .addMatcher(
        openAiAPI.endpoints.loadGenerationProcess.matchRejected,
        (state) => {
          state.totalAds = 0;
          state.currentAds = 0;
          return state;
        }
      );
  },
});

export const {
  setCampaignName,
  setSegmentID,
  setCampaignHypothesis,
  setSelectedGoogleAds,
  setSelectedGoogleAdsCampaign,
  setCampaignSiteUrl,
  setSelectedElements,
  setDeleteElementById,
  setDeleteElementByPath,
  setAdsElements,
  setDestinationUrl,
  setGoogleAdsReset,
  setDistributionType,
  setActionsID,
  setClearElements,
  setClearCampaignData,
  setCampaignId,
  setIsDeletionDone,
  setFilters,
  setClearFilters,
  setGoogleAdsUrls,
  setCopyToEmptyAd,
  setCopyToAllAd,
  setCurrentAds,
  setTotalAds,
  setGenerationType,
} = campaignSlice.actions;

export default campaignSlice.reducer;

interface ChangeAdsElement {
  adsID: number;
  elementIndex: number;
  newElementValue?: any;
  destination_url?: string;
}
