import { Checkbox } from '@pipedataai/storybook';

interface IAdCheckbox {
  id: string;
  isChecked: boolean;
  title: string;
  onHandleCheck: (id: string) => void;
}

const AdCheckbox = ({ id, title, isChecked, onHandleCheck }: IAdCheckbox) => {
  return (
    <label className="flex">
      <Checkbox
        id={id}
        checked={isChecked}
        onHandleCheck={() => onHandleCheck(id)}
      />
      <div className="ml-4">
        <p className="text-sm font-medium text-gray-900">{title}</p>
        <p className="text-sm font-normal text-gray-500">ID: {id}</p>
      </div>
    </label>
  );
};

export default AdCheckbox;
