import { useState } from 'react';
import { BreadCrumbs } from '@pipedataai/storybook';

import SegmentedCompanies from 'src/features/segments/blocks/segmented-companies';
import CreateSegment from 'src/features/segments/create-segment';
import useClearDataNavigate from 'src/helpers/useClearDataNavigate';

export const CreateSegmentsPage = () => {
  const [count, setCount] = useState(0);
  const { handleNavigate } = useClearDataNavigate();
  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <div className="mb-4">
        <BreadCrumbs
          items={[
            { title: 'Segments', link: '/segments' },
            { title: 'New Segment', link: '/segments/create-segment' },
          ]}
          onNavigateClick={handleNavigate}
        />
      </div>

      <div className="flex items-center justify-between mb-[42px]">
        <h1 className="text-2xl font-semibold">New Segment</h1>
      </div>

      <CreateSegment setCount={setCount} />
      <SegmentedCompanies count={count} />
    </section>
  );
};
