import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { HiExclamation } from 'react-icons/hi';
import { Button, ModalBox } from '@pipedataai/storybook';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setRedirectAfterSync } from 'src/store/slices/storageSlice';
import {
  useLazyCheckIsSyncedGoogleQuery,
  useLazySyncGoogleAccountQuery,
} from 'src/store/services/google/endpoints/google';
import { setSyncError } from 'src/store/slices/googleSyncSlice';

import { customToastMessage } from 'src/components/toast';

export const showToast = (message: string, status: string) => {
  customToastMessage(message, <HiExclamation className="h-5 w-5" />, status);
};

const SyncAccessModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showAccessModal, setShowAccessModal] = useState<boolean>(false);
  const { redirectAfterSync } = useAppSelector((state) => state.storageReducer);
  const { latestSync } = useAppSelector((state) => state.googleSyncReducer);
  const [syncGoogleAccount] = useLazySyncGoogleAccountQuery();
  const [checkIsSyncedGoogle] = useLazyCheckIsSyncedGoogleQuery();

  const handleSync = async () => {
    await syncGoogleAccount();
    await checkIsSyncedGoogle();
    dispatch(setRedirectAfterSync(''));
    dispatch(setSyncError(undefined));
    setShowAccessModal(false);
  };

  const handleRedirectWithOutSync = () => {
    navigate(redirectAfterSync);
    dispatch(setRedirectAfterSync(''));
    setShowAccessModal(false);
  };

  const handleCloseSyncModal = () => {
    dispatch(setRedirectAfterSync(''));
    dispatch(setSyncError(undefined));
    setShowAccessModal(false);
  };

  useEffect(() => {
    if (redirectAfterSync) {
      setShowAccessModal(true);
    }
  }, [redirectAfterSync]);

  return latestSync ? (
    <ModalBox
      isOpen={showAccessModal}
      onClose={handleCloseSyncModal}
      windowClassName="max-w-md"
    >
      <ModalBox.Body>
        <p className="text-sm font-medium text-[#111928]">
          Your Google Ads data was last synced on{' '}
          {format(Date.parse(latestSync), 'MMM d')}, at{' '}
          {format(Date.parse(latestSync), 'h:mm a')}.
          <br />
          Would you like to proceed with this data?
        </p>
      </ModalBox.Body>
      <ModalBox.Footer className="border-t-0">
        <div className="flex justify-between">
          <Button
            baseType="default"
            baseTitle="Sync Now"
            className="min-w-[100px] justify-center"
            onClick={handleSync}
          />
          <Button
            baseType="outline"
            baseTitle="Proceed"
            className="min-w-[100px] mr-0 justify-center"
            onClick={handleRedirectWithOutSync}
          />
        </div>
      </ModalBox.Footer>
    </ModalBox>
  ) : null;
};

export default SyncAccessModal;
