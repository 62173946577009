import { format } from 'date-fns';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Dropdown, DropItem } from '@pipedataai/storybook';
import { Edit, Trash } from '@pipedataai/storybook/dist/components/icons/icons';

import { ConversionActionType } from 'src/store/services/conversion-action/types';

import TableHeadTitle from '../campaigns/blocks/table-head-title';

interface IConversionTableProps {
  handleEdit: (item: any) => void;
  handleDelete: (id: string) => void;
}

export const TABLE_ACTIONS_COLUMNS = ({
  handleEdit,
  handleDelete,
}: IConversionTableProps) => [
  {
    key: 'conversion_action',
    label: () => <TableHeadTitle className="pl-6" title="Conversion action" />,
    width: '21%',
    render: (rowItems: any) => (
      <p className="py-1 px-3 text-sm text-gray-900 font-semibold">
        {rowItems.name}
      </p>
    ),
  },
  {
    key: 'conversion_count',
    label: () => (
      <TableHeadTitle
        className="pl-6"
        title="Conversions"
        textPosition="right"
      />
    ),
    width: '19%',
    render: (rowItems: any) => (
      <p className="py-1 px-3 text-right text-gray-900 text-sm font-medium">
        {rowItems.count_conversions}
      </p>
    ),
  },
  {
    key: 'conversion_type',
    label: () => <TableHeadTitle className="pl-6" title="Type" />,
    width: '19%',
    render: (rowItems: any) => (
      <p className="py-1 px-3 text-gray-900 text-sm font-medium whitespace-nowrap">
        {ConversionActionType[rowItems.action_type].replaceAll('_', ' ')}
      </p>
    ),
  },
  {
    key: 'conversion_last',
    label: () => <TableHeadTitle className="pl-6" title="Last conversion" />,
    width: '19%',
    render: (rowItems: any) => (
      <p className="py-1 px-3 text-gray-900 text-sm font-medium whitespace-nowrap">
        {rowItems.last_conversion
          ? format(Date.parse(rowItems.last_conversion), 'dd-MMM-yyyy')
          : '_'}
      </p>
    ),
  },
  {
    key: 'conversion_create',
    label: () => <TableHeadTitle className="pl-6" title="Created" />,
    width: '19%',
    render: (rowItems: any) => (
      <p className="py-1 px-3 text-gray-900 text-sm font-medium whitespace-nowrap">
        {format(Date.parse(rowItems.created_at), 'dd-MMM-yyyy')}
      </p>
    ),
  },
  {
    key: 'dot_popup',
    label: '',
    width: '2%',
    render: (rowItems: any) => (
      <div className="flex">
        <Dropdown
          className="[&>div]:py-0"
          label={
            <div className="flex items-center justify-center bg-gray-100 w-[22px] h-[22px] rounded">
              <HiDotsHorizontal />
            </div>
          }
        >
          <DropItem
            className="py-2"
            icon={<Edit className="mr-2" />}
            onClick={() => handleEdit(rowItems)}
          >
            <span className="text-sm font-normal text-gray-700">Edit</span>
          </DropItem>
          <DropItem
            className="py-2"
            icon={<Trash className="mr-3" color="#E02424" />}
            onClick={() => handleDelete(rowItems.id)}
          >
            <span className="text-sm font-normal text-red-600">Delete</span>
          </DropItem>
        </Dropdown>
      </div>
    ),
  },
];

export const VISIT_ITEMS = [
  {
    label: 'contains',
    value: 'false',
  },
  {
    label: 'exactly',
    value: 'true',
  },
];
