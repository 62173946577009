import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HiExclamation } from 'react-icons/hi';
import { BreadCrumbs, Spinner } from '@pipedataai/storybook';

import CreateSegment from 'src/features/segments/create-segment';
import useUpdateDataTransform from 'src/features/segments/hooks/useUpdateDataTransform';
import SegmentedCompanies from 'src/features/segments/blocks/segmented-companies';

import { customToastMessage } from 'src/components/toast';
import useClearDataNavigate from 'src/helpers/useClearDataNavigate';

export const UpdateSegmentsPage = () => {
  const { id } = useParams();
  const { handleNavigate } = useClearDataNavigate();
  const { isLoading, segment, error } = useUpdateDataTransform(id || '');
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (error && !isLoading) {
      customToastMessage(
        error,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
    }
  }, [isLoading, error]);

  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <div className="mb-4">
        <BreadCrumbs
          items={[
            { title: 'Segments', link: '/segments' },
            { title: 'Update Segment', link: '/segments/update-segment' },
          ]}
          onNavigateClick={handleNavigate}
        />
      </div>

      <div className="flex items-center justify-between mb-[42px]">
        <h1 className="text-2xl font-semibold">Update Segment</h1>
      </div>
      {isLoading ? (
        <div className="w-full h-[calc(100vh_-_225px)] flex items-center justify-center">
          <Spinner color="fill-blue-500" />
        </div>
      ) : (
        <CreateSegment segmentID={id} segment={segment} setCount={setCount} />
      )}
      <SegmentedCompanies count={count} />
    </section>
  );
};
