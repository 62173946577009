import { useAppDispatch } from 'src/store/hooks';
import {
  useLazyGetFixedTextQuery,
  useLazyGetGenerateTextQuery,
  useLazyGetImprovedTextQuery,
} from 'src/store/services/create-campaign/endpoints/create-campaign';
import { parseResponse } from 'src/store/services/helpers';
import { setAdsElements } from 'src/store/slices/campaignSlice';

import { TGPTGeneration } from '../blocks/ad-elements';

const useGPT = (
  showToast: (message: string, type: string) => void,
  googleAdsData: any,
  handleChatDisabled: (value: boolean) => void
) => {
  const dispatch = useAppDispatch();
  const [getGenerateText, { isFetching: generateFetching }] =
    useLazyGetGenerateTextQuery();
  const [getFixedText, { isFetching: fixedFetching }] =
    useLazyGetFixedTextQuery();
  const [getImprovedText, { isFetching: improvedFetching }] =
    useLazyGetImprovedTextQuery();

  const handleGPTGenerate = async ({
    element,
    index,
    adsID,
  }: TGPTGeneration): Promise<string> => {
    handleChatDisabled(true);
    const ads = googleAdsData.find((ad: any) => ad.id === adsID);
    const adsKeywords = ads.keywords
      ? Array.from(
          new Map(
            ads.keywords
              .sort((a: any, b: any) => {
                if (a.clicks === null && b.clicks === null) return 0;
                if (a.clicks === null) return 1;
                if (b.clicks === null) return -1;
                return b.clicks - a.clicks;
              })
              .map((key: any) => [key.keyword, key])
          ).values()
        )
          .map((key: any) => key.keyword)
          .slice(0, 4)
      : [];

    const { data, error } = await getGenerateText({
      adsHeadline: ads.title.split('|'),
      adsDescription: ads.description.split('|'),
      keywords: adsKeywords,
      elementName: element.name,
      originalText: element.default_value,
    });
    const { result, errorMessage } = parseResponse({ data, error });
    if (error && errorMessage) {
      showToast(errorMessage, 'error');
      return '';
    }
    if (result) {
      dispatch(
        setAdsElements({
          adsID,
          elementIndex: index,
          newElementValue: result.data,
        })
      );
    }
    return result ? result.data : '';
  };

  const handleGPTFixText = async ({
    element,
    index,
    adsID,
  }: TGPTGeneration): Promise<string> => {
    handleChatDisabled(true);
    const { data, error } = await getFixedText({
      originalText: element.personalized_value || element.default_value,
    });
    const { result, errorMessage } = parseResponse({ data, error });
    if (error && errorMessage) {
      showToast(errorMessage, 'error');
      return '';
    }
    if (result) {
      dispatch(
        setAdsElements({
          adsID,
          elementIndex: index,
          newElementValue: result.data,
        })
      );
    }
    return result ? result.data : '';
  };

  const handleGPTImproveText = async ({
    element,
    index,
    adsID,
  }: TGPTGeneration): Promise<string> => {
    handleChatDisabled(true);
    const { data, error } = await getImprovedText({
      originalText: element.personalized_value || element.default_value,
    });
    const { result, errorMessage } = parseResponse({ data, error });
    if (error && errorMessage) {
      showToast(errorMessage, 'error');
      return '';
    }
    if (result) {
      dispatch(
        setAdsElements({
          adsID,
          elementIndex: index,
          newElementValue: result.data,
        })
      );
    }
    return result ? result.data : '';
  };

  const handleBackAnswer = (value: string, index: number, adsID: number) => {
    dispatch(
      setAdsElements({
        adsID,
        elementIndex: index,
        newElementValue: value,
      })
    );
    return value;
  };

  return {
    handleGPTGenerate,
    handleGPTFixText,
    handleGPTImproveText,
    handleBackAnswer,
    fetchLoading: generateFetching || fixedFetching || improvedFetching,
  };
};

export default useGPT;
