import { useCallback, useEffect, useState } from 'react';
import { Button, Input, ModalBox, Spinner } from '@pipedataai/storybook';
import { HiExclamation } from 'react-icons/hi';
import { parseResponse } from 'src/store/services/helpers';
import { GoogleCustomers } from 'src/store/services/google/types';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setClearState,
  setGoogleSignIn,
  setSync,
} from 'src/store/slices/googleSyncSlice';
import {
  useDetachGoogleMutation,
  useLazyGetLoadCustomersQuery,
  useLazyGetSyncMailQuery,
  useLazySyncGoogleAccountQuery,
  useSetSyncCustomersMutation,
} from 'src/store/services/google/endpoints/google';
import { customToastMessage } from 'src/components/toast';
import AdCheckbox from './ad-checkbox';
import VirtualizedList from './virtualized-list';

interface ISyncAdsModalProp {
  showSyncModal: boolean;
  handleHideSyncModal: () => void;
  syncLoad: boolean;
  firstSync: boolean;
}

const formatId = (id: string): string => {
  const idStr = id.toString();
  const formattedId = idStr.match(/.{1,3}/g)?.join('-') || '';
  return formattedId;
};

const SyncAdsModal = ({
  showSyncModal,
  handleHideSyncModal,
  syncLoad,
  firstSync,
}: ISyncAdsModalProp) => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [confirm, setConfirm] = useState('');
  const [customers, setCustomers] = useState<GoogleCustomers[]>([]);
  const [initialCustomers, setInitialCustomers] = useState<GoogleCustomers[]>(
    []
  );

  const { signInGoogle, syncMail } = useAppSelector(
    (state) => state.googleSyncReducer
  );

  const [syncGoogleAccount] = useLazySyncGoogleAccountQuery();
  const [getLoadCustomers, { isLoading: customerLoad }] =
    useLazyGetLoadCustomersQuery();
  const [getSyncMail] = useLazyGetSyncMailQuery();
  const [setSyncCustomers] = useSetSyncCustomersMutation();
  const [detachGoogle] = useDetachGoogleMutation();

  const handleConfirm = async () => {
    dispatch(setSync({ isSynced: 2 }));
    dispatch(setGoogleSignIn(true));
    handleHideSyncModal();
    await setSyncCustomers(customers);
    await syncGoogleAccount();
    await getSyncMail();
    setConfirm('');
  };

  const handleUnselectAll = (all: boolean) => {
    const unselectArr = customers.map((customer) => ({
      ...customer,
      to_sync: !all,
    }));
    setCustomers(unselectArr);
  };

  const handleSelectCustomer = (id: string) => {
    const selectArr = customers.map((customer) => {
      if (id === formatId(customer.customer_id)) {
        return {
          ...customer,
          to_sync: !customer.to_sync,
        };
      }
      return customer;
    });
    setCustomers(selectArr);
  };

  const handleGoogleDisconnect = () => {
    detachGoogle();
    dispatch(setClearState());
    handleHideSyncModal();
    setConfirm('');
  };

  const handleConfirmation = () => {
    if (confirm === 'detach') {
      handleGoogleDisconnect();
      setConfirm('');
      return;
    }
    handleConfirm();
    setConfirm('');
  };

  const loadCustomers = useCallback(async () => {
    if (!showSyncModal) return;

    try {
      const { data, error } = await getLoadCustomers({});
      const { result, errorMessage } = parseResponse({ data, error });
      if (error && errorMessage) {
        customToastMessage(
          errorMessage,
          <HiExclamation className="h-5 w-5" />,
          'error'
        );
      }

      if (result && !errorMessage) {
        const customers =
          result.data.length > 0
            ? result.data.filter((customer) => !customer.manager)
            : result.data;

        const selectedCustomers = customers.find((customer) => customer.to_sync)
          ? customers
          : customers.map((customer) => {
              return {
                ...customer,
                to_sync: true,
              };
            });

        setCustomers(customers);
        setInitialCustomers(selectedCustomers);
      }
    } catch (error: any) {
      customToastMessage(
        error.message,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      handleHideSyncModal();
    }
  }, [getLoadCustomers, handleHideSyncModal, showSyncModal]);

  useEffect(() => {
    if (!syncLoad) {
      loadCustomers();
    }
  }, [loadCustomers, syncLoad]);

  useEffect(() => {
    const filteredCustomers = initialCustomers.filter((customer) => {
      const searchValueLower = searchValue.toLowerCase();
      const customerNameLower = customer.name.toLowerCase();
      const customerIdFormatted = formatId(customer.customer_id).toLowerCase();
      const customerIdRaw = customer.customer_id.toLowerCase();
      return (
        customerNameLower.includes(searchValueLower) ||
        customerIdFormatted.includes(searchValueLower) ||
        customerIdRaw.includes(searchValueLower)
      );
    });

    const selectedCustomers = filteredCustomers.filter(
      (customer) => customer.to_sync
    );
    const unselectedCustomers = filteredCustomers.filter(
      (customer) => !customer.to_sync
    );

    setCustomers([...selectedCustomers, ...unselectedCustomers]);
  }, [searchValue, initialCustomers]);

  const hasUnselectedAccounts = () => {
    return initialCustomers.some(
      (initialCustomer) =>
        initialCustomer.to_sync &&
        !customers.find(
          (customer) => customer.customer_id === initialCustomer.customer_id
        )?.to_sync
    );
  };

  const handleSyncConfirm = () => {
    if (firstSync) {
      handleConfirm();
      return;
    }

    if (hasUnselectedAccounts()) {
      setConfirm('sync');
    } else {
      handleConfirm();
    }
  };

  return (
    <>
      <ModalBox
        isOpen={showSyncModal}
        windowClassName="flex flex-col max-w-4xl max-h-[75vh]"
        onClose={handleHideSyncModal}
      >
        <ModalBox.Header onClose={handleHideSyncModal}>
          <div>
            <p className="text-xl font-semibold text-gray-900">
              Choose Google Ads accounts
            </p>
            <p className="text-sm font-normal text-gray-500">
              Select the accounts you want to sync data from
            </p>
          </div>
        </ModalBox.Header>
        <ModalBox.Body className="border-b p-4">
          <div className="flex flex-col gap-y-[16px]">
            <Input
              id="buttons-input"
              value={searchValue}
              placeholder="Search accounts"
              className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none"
              onChange={(e) => setSearchValue(e.target.value)}
              onClearClick={() => setSearchValue('')}
              searchInput={true}
              disabled={syncLoad}
            />
          </div>
        </ModalBox.Body>
        {syncLoad || customerLoad ? (
          <ModalBox.Body className="flex items-center justify-center">
            <Spinner size="w-10 h-10" />
          </ModalBox.Body>
        ) : (
          <ModalBox.Body className="flex flex-col [&]:p-0 overflow-hidden">
            <VirtualizedList
              items={customers}
              renderItem={(customer: GoogleCustomers) => (
                <AdCheckbox
                  key={customer.customer_id}
                  id={formatId(customer.customer_id)}
                  isChecked={customer.to_sync}
                  title={customer.name}
                  onHandleCheck={handleSelectCustomer}
                />
              )}
            />
          </ModalBox.Body>
        )}
        <ModalBox.Footer className="flex items-center justify-between">
          <div className="flex">
            <p className="text-sm font-normal text-gray-600">
              {customers.filter((item) => item.to_sync).length} of{' '}
              {customers.length} selected
            </p>
            <button
              className="ml-7"
              onClick={() =>
                handleUnselectAll(
                  customers.filter((item) => item.to_sync).length ===
                    customers.length
                )
              }
            >
              <p className="text-sm font-medium text-blue-600">
                {customers.filter((item) => item.to_sync).length ===
                customers.length
                  ? 'Deselect all'
                  : 'Select all'}
              </p>
            </button>
          </div>
          <div className="flex">
            {signInGoogle && (
              <button className="mr-7" onClick={() => setConfirm('detach')}>
                <p className="text-sm font-medium text-red-600">
                  Delete connection
                </p>
              </button>
            )}
            <Button
              onClick={handleSyncConfirm}
              baseTitle="Confirm"
              disabled={customers.filter((item) => item.to_sync).length === 0}
            />
          </div>
        </ModalBox.Footer>
      </ModalBox>
      <ModalBox
        isOpen={!!confirm}
        onClose={() => setConfirm('')}
        windowClassName="max-w-xl"
      >
        <ModalBox.Body>
          <p className="mb-4 text-xl font-semibold text-gray-900">
            {confirm === 'detach' && 'Delete connection'}
            {confirm === 'sync' && 'Account Synchronization Changes'}
          </p>
          {confirm === 'sync' && (
            <p className="mb-4 text-base font-normal text-gray-500">
              The list of Google Ads accounts used for synchronization has
              changed.
              <br />
              Data from Google Ads accounts that are now unchecked will remain
              available for existing campaigns, but new data will no longer be
              retrieved.
            </p>
          )}
          {confirm === 'detach' && (
            <p className="mb-4 text-base font-normal text-gray-500">
              This action will delete {syncMail}. This cannot be undone.
              <br />
              Data retrieved through this connection will no longer be updated.
              <br />
            </p>
          )}
          <div className="flex justify-end">
            <Button
              baseType="danger"
              className="mr-4"
              baseTitle={confirm === 'detach' ? 'Delete connection' : 'Confirm'}
              onClick={() => handleConfirmation()}
            />
            <Button
              baseType="gray"
              className="[&]:bg-gray-200"
              baseTitle="Cancel"
              onClick={() => setConfirm('')}
            />
          </div>
        </ModalBox.Body>
      </ModalBox>
    </>
  );
};

export default SyncAdsModal;
