import { useLocation } from 'react-router-dom';
import {
  useCalcCRLift as getCalcCRLift,
  useCalcCR as getCalcCR,
} from '@pipedataai/storybook';

import {
  DetailAdElements,
  DetailCampaignAd,
  EAdStatus,
} from 'src/store/services/create-campaign/types';
import { useAppSelector } from 'src/store/hooks';
import { useGetCampaignRecommendationsQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';

import { TTABLE_ELEMENT } from '../blocks/ad-elements';
import { TStatus } from '../blocks/recommendation';

interface IAdsTableData {
  id: string;
  status: EAdStatus;
  adGroup: string;
  title: string;
  description: string;
  url: string;
  visitors: number;
  leads: number;
  contLeads: number;
  persLeads: number;
  crCont: number | string;
  crPers: number;
  crLift: string;
  midCrs: number;
  differentPreview: boolean;
  isPersonalized: boolean;
  elements: TTABLE_ELEMENT[];
  type: TStatus;
  updated: string;
}

const ELEMENT_TAG_TYPE = ['text', 'button', 'image'];

const dynamicSort = (
  array: IAdsTableData[],
  property: keyof IAdsTableData,
  order: 'ASC' | 'DESC'
) => {
  const sortOrder = order === 'ASC' ? 1 : -1;

  return array.sort((a, b) => {
    const valueA =
      typeof a[property] === 'number'
        ? a[property]
        : parseFloat(a[property] as string);
    const valueB =
      typeof b[property] === 'number'
        ? b[property]
        : parseFloat(b[property] as string);

    if (valueA < valueB) {
      return sortOrder;
    } else if (valueA > valueB) {
      return -sortOrder;
    } else {
      return 0;
    }
  });
};

const useDetailDataTransform = (
  ads: DetailCampaignAd[] | undefined,
  campaignID: string
) => {
  const location = useLocation();
  const { data: recommendations } =
    useGetCampaignRecommendationsQuery(campaignID);
  const foundFilter = location.search.match(/\?(.*?)=([^&]+)/);
  const { filters } = useAppSelector((state) => state.createCampaignReducer);

  const findClosestDate = (data: DetailAdElements[]) => {
    const currentDate = new Date();

    const dateDifference = (date1: Date, date2: Date): number => {
      return Math.abs(date1.getTime() - date2.getTime());
    };

    return data.reduce(
      (closest: DetailAdElements | null, item: DetailAdElements) => {
        if (item) {
          const itemDate = new Date(item.update_dt);

          if (!closest) {
            return item;
          }

          const closestDate = new Date(closest.update_dt);
          if (
            !closest ||
            dateDifference(itemDate, currentDate) <
              dateDifference(closestDate, currentDate)
          ) {
            return item;
          }
        }

        return closest;
      },
      null
    );
  };

  let adsTransform: IAdsTableData[] = [];
  if (ads) {
    ads.forEach((item: DetailCampaignAd) => {
      const updateAd = findClosestDate(item.elements);
      adsTransform.push({
        id: item.id,
        status: item.status,
        adGroup: item.group.name,
        title: item.name.map((name) => name.headline).join(' | '),
        description: item.description
          .map((desc) => desc.description)
          .join(' | '),
        url: item.url,
        visitors: +item.experimental_visitors + +item.full_visitors,
        contLeads: +item.full_leads,
        persLeads: +item.experimental_leads,
        leads: +item.experimental_leads + +item.full_leads,
        crCont: item.isPersonalized
          ? getCalcCR({
              visitors: +item.full_visitors,
              leads: +item.full_leads,
            })
          : 0,
        crPers: item.isPersonalized
          ? getCalcCR({
              visitors: +item.experimental_visitors,
              leads: +item.experimental_leads,
            })
          : 0,
        crLift: item.isPersonalized
          ? getCalcCRLift({
              full_visitors: +item.full_visitors,
              experimental_visitors: +item.experimental_visitors,
              full_leads: +item.full_leads,
              experimental_leads: +item.experimental_leads,
              detailLine: true,
            })
          : '0%',
        midCrs: item.isPersonalized
          ? +(
              (getCalcCR({
                visitors: +item.full_visitors,
                leads: +item.full_leads,
              }) +
                getCalcCR({
                  visitors: +item.experimental_visitors,
                  leads: +item.experimental_leads,
                })) /
              2
            ).toFixed(2)
          : +(
              (+item.experimental_leads + +item.full_leads) /
              (+item.experimental_visitors + +item.full_visitors)
            ).toFixed(2),
        differentPreview: !!item.elements.find(
          (item) =>
            item.personalized_value &&
            item.personalized_value.includes(', fallback value:')
        ),
        isPersonalized: item.isPersonalized,
        type: recommendations?.find((rec) => rec.google_ad_id === item.id)
          ?.recommendation as TStatus,
        updated: updateAd ? updateAd.update_dt : '',
        elements: item.elements
          .map((element) => {
            return {
              id: element.id,
              name: element.element.name,
              default_value: element.element.default_value,
              personalized_value: element.personalized_value,
              destination_url: element.destination_url,
              default_url: element.element.default_url,
              element_type: ELEMENT_TAG_TYPE[element.element.tag_type - 1],
            };
          })
          .sort((a, b) => (a.id > b.id ? 1 : -1)),
      });
    });
  }
  if (foundFilter) {
    dynamicSort(
      adsTransform,
      foundFilter[1] as keyof IAdsTableData,
      foundFilter[2] as 'ASC' | 'DESC'
    );
  }

  if (filters && filters.ads && filters.ads?.length > 0) {
    adsTransform = adsTransform.filter((ad) => filters.ads?.includes(ad.id));
  }

  return { adsTransform };
};

export default useDetailDataTransform;
