type JenericType = {
  [key: string]: string;
};

export interface GoogleADSCustomers {
  user_id: string;
  customer_id: string;
  name: string;
  manager: boolean;
  parent_id: string;
}

export interface HeadDescriptionItem {
  title: string;
  imp: number;
}

export interface GoogleAdsData {
  id: string;
  group_name: string;
  status: EAdStatus;
  ad_type: number;
  campaign_id: string;
  url: string;
  ctr: number;
  clicks: number;
  impressions: number;
  name: HeadDescriptionItem[];
  description: HeadDescriptionItem[];
  group: GoogleAdGroup;
  elements?: IElement[];
  keywords?: KeywordDto[] | string[];
  keywordsObjects?: IKeywordObject[];
  isPersonalized?: boolean;
}

export interface ExtendedURLs {
  ads_count: number;
  url: string;
  google_campaign_id: string;
  personalized_experience_id?: string;
}

export interface GoogleCampaigns {
  id: string;
  google_customer_id: string;
  name: string;
  status: number;
  extended_urls: ExtendedURLs[];
}

export interface DraftData {
  campaign_id: string | undefined;
  name: string;
  traffic_source?: number;
  google_ads_id: string;
  google_campaign_id: string;
  page_url?: string;
  personalized_ads?: GoogleAdsData[];
  distribution_type?: number;
  elements?: IElement[];
  actions_id?: string[];
  hypothesis_description?: string;
  segment_id?: string;
  status?: CampaignItemStatus;
}

export interface CampaignListItem {
  id: number;
  name: string;
  distribution_type: number;
  created_at: string;
  full_visitors: number;
  full_leads: number;
  experimental_visitors: number;
  experimental_leads: number;
  avg_session: number;
  bounce: number;
  status: CampaignItemStatus;
}

interface DetailDefaultElement {
  id: number;
  name: string;
  default_value: string;
  default_url: string;
  tag_type: number;
}

export interface DetailAdElements {
  id: number;
  personalized_value: string;
  destination_url: string;
  element: DetailDefaultElement;
  update_dt: string;
}

export interface IUserCampaign {
  created_at: string;
  updated_at: string;
  critical_update_at?: string;
  id: string;
  name: string;
  page_url: string;
  traffic_source: number;
  google_campaign_id: string;
  segment_id: string;
  status: CampaignItemStatus;
  distribution_type: number;
}

export interface IDetailCampaign {
  name: string;
  status: CampaignItemStatus;
  user_campaign: IUserCampaign;
}

export interface DetailCampaignAd {
  id: string;
  url: string;
  ctr: number;
  clicks: number;
  impressions: number;
  ad_group_id: string;
  campaign: IDetailCampaign;
  name: JenericType[];
  description: JenericType[];
  elements: DetailAdElements[];
  group: JenericType;
  full_visitors: number;
  experimental_visitors: number;
  full_leads: number;
  experimental_leads: number;
  status: EAdStatus;
  isPersonalized: boolean;
}

export interface IDetailCampaignProp {
  ads: DetailCampaignAd[];
  campaign: IDetailCampaign;
  chart: ChartData;
  total: TStatistic;
  emptyCampaign?: {
    id: string;
    name: string;
  };
}

export interface IExtraCampaignProp {
  ads: DetailCampaignAd[];
  campaign?: {
    id: string;
    name: string;
  };
}

export interface FilterCalendarDate {
  id?: string;
  startDate?: string | undefined;
  endDate?: string | undefined;
  version?: string | undefined;
  ads?: string[];
  is_desktop?: string;
  conversions?: string;
  part?: string;
}

export enum CampaignItemStatus {
  Draft = 1,
  Paused = 2,
  Active = 3,
}

export enum EAdStatus {
  ENABLED = 2,
  PAUSED = 3,
  REMOVED = 4,
  UNKNOWN = 1,
  UNSPECIFIED = 0,
}

export enum KeywordType {
  UNSPECIFIED = 0,
  UNKNOWN = 1,
  EXACT = 2,
  PHRASE = 3,
  BROAD = 4,
}

export const KeywordTypeLabels: { [key in KeywordType]: string } = {
  [KeywordType.UNSPECIFIED]: 'unsp',
  [KeywordType.UNKNOWN]: 'unkn',
  [KeywordType.EXACT]: 'exct',
  [KeywordType.PHRASE]: 'phrs',
  [KeywordType.BROAD]: 'brd',
};

export interface GoogleAds {
  label: string;
  value: string;
  has_company?: boolean;
}

export interface IElement {
  id?: string;
  personalized_element_id?: string;
  personalized_id?: string;
  name: string;
  path: string;
  default_url: string;
  default_value: string;
  personalized_value?: string;
  destination_url?: string;
  tag_type: string;
  campaign_id?: string;
  element?: IElement;
  deletion?: boolean;
}

export interface PersonalizedAds {
  id?: string;
  personalized_value: string;
  personalized_element_id?: string;
  google_ad_id: string;
}

export interface GoogleAdGroup {
  id: string;
  name: string;
  google_campaign_id: string;
  mid_quality_score: string;
  landing_page_exp: string;
}

export interface GenerateTextQueryPRop {
  adsHeadline: string[];
  adsDescription: string[];
  keywords: string[];
  elementName: string;
  originalText: string;
}

export interface FixTextQueryPRop {
  originalText: string;
}

export interface KeywordDto {
  ad_group_id: number;
  keyword: string;
  campaign_id: number;
}

export interface IKeywordObject {
  clicks: number | null;
  cost_micros: number | null;
  ctr: number | null;
  impressions: number | null;
  keyword: string;
  match_type: string;
}

export interface GetCampaignData {
  campaign: {
    id: string;
    name: string;
    page_url: string;
    google_campaign_id: string;
    hypothesis_description?: string;
    segment_id?: string;
    googleCampaign: {
      google_customer_id: string;
      keywords: KeywordDto[];
    };
    distribution_type: number;
    conversion_actions: [
      {
        conversion_action_id: string;
      }
    ];
    status: CampaignItemStatus;
  };
  ads: GoogleAdsData[];
  elements: IElement[];
}

export interface CampaignVersions {
  id: string;
  created_at: string;
  last_updated_at: string;
}

export interface CampaignFilters {
  startDate?: string;
  endDate?: string;
  versionID?: string;
  ads?: string[];
  conversions?: string;
  is_desktop?: string;
  part?: string;
}

export type TStatistic = {
  ctrl_conversions?: number;
  ctrl_visitors?: number;
  experimental_conversions?: number;
  experimental_visitors?: number;
};

type ChartConversion = {
  is_experimental: Array<boolean | null>;
  visitor_count: Array<number | null>;
  conversion_count: Array<number | null>;
  conversion_actions_with_count: Array<string | null>;
};

type ConversionData = {
  [adId: string]: TStatistic;
  total: TStatistic;
};

export interface ChartData {
  axisX: string[];
  control: ChartConversion;
  experimental: ChartConversion;
  reportByAds: ConversionData;
}

export interface ChartFiltersDto {
  campaign_id: string;
  ads?: string[];
  conversions?: string;
  is_desktop?: string;
  startDate?: string;
  endDate?: string;
  part?: string;
}

export interface RecommendationsAds {
  campaign_id: string;
  google_ad_id: string;
  ad_update_dt: string;
  visitors_exp?: number;
  conversions_exp?: number;
  visitors_ctrl?: number;
  conversions_ctrl?: number;
  cr_lift?: number;
  recommendation: string;
}

export interface CampaignConversion {
  id: string;
  name: string;
  action_type: number;
  tag_path: string;
  page_url: string;
  is_exactly: boolean;
  user_id: string;
  status: number;
  created_at: string;
}
