import { userAPI } from '..';
import { ResponseWrap, SuccessMessageResponse } from '../../response_wrap';
import { ChangePasswordRequest, UserProfile } from '../types';

export const userDataAPI = userAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<ResponseWrap<UserProfile>, void>({
      query: () => ({
        url: 'api/users/profile',
      }),
    }),
    checkVerifyUrl: builder.query<ResponseWrap<boolean>, void>({
      query: () => ({
        url: 'api/users/check-verify-url',
      }),
    }),
    getPromo: builder.query<ResponseWrap<{ promo: string }>, void>({
      query: () => ({
        url: '/api/promo/load',
      }),
      providesTags: ['getPromo'],
    }),
    changePassword: builder.mutation<
      ResponseWrap<SuccessMessageResponse>,
      ChangePasswordRequest
    >({
      query: (body) => ({
        url: 'api/users/password',
        method: 'PUT',
        body: body,
      }),
    }),
    addPromo: builder.mutation<any, { promo_code: string }>({
      query: (body) => ({
        url: '/api/promo/set',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['getPromo'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useCheckVerifyUrlQuery,
  useLazyGetProfileQuery,
  useGetPromoQuery,
  useLazyGetPromoQuery,

  useChangePasswordMutation,
  useAddPromoMutation,
} = userDataAPI;
