import { getTypeElement } from './getElementByHover';

export const parseURL = (url: string) => {
  const proxyIndex = url.indexOf('/custom-proxy');
  if (proxyIndex !== -1) {
    const startIndex = url.indexOf('=') + 1;
    const correctURL = url.slice(startIndex);
    return correctURL.replace(/([^:]\/)\/+/g, '$1');
  } else {
    return url.replace(/([^:]\/)\/+/g, '$1');
  }
};

export const getSelectedElementValue = (element: any) => {
  const type = getTypeElement(element);
  const tag = element.tagName.toLowerCase();

  if (element.src && type === 'image') {
    return parseURL(element.src);
  }

  if (tag === 'input' && type === 'button') {
    return element.value;
  }

  const backgroundImage = getComputedStyle(element).backgroundImage;
  if (backgroundImage && backgroundImage.includes('url') && type === 'image') {
    // Extract the URL of the background image from the background-image value.
    const urlRegex = /url\(['"]?(.*?)['"]?\)/;
    const matches = backgroundImage.match(urlRegex);
    if (matches && matches.length > 1) {
      return parseURL(matches[1]);
    }
  }

  if (element.textContent && (type === 'text' || type === 'button')) {
    return element.textContent;
  }

  return null;
};
