import { useRef } from 'react';
import { format } from 'date-fns';

import { UserSub } from 'src/store/services/user/types';

import ProfileSection from './profile-section';

interface IUserCard {
  avatar: string;
  email: string;
  companyName: string;
  plan: UserSub;
  withPromo: boolean;
}

const UserCard = ({
  avatar,
  email,
  companyName,
  plan,
  withPromo,
}: IUserCard) => {
  const avatarRef = useRef<HTMLImageElement>(null);

  const handleChangeErrorImage = () => {
    if (avatarRef && avatarRef.current) {
      avatarRef.current.src = '/companies/company_1.png';
    }
  };

  return (
    <ProfileSection>
      <div className="flex gap-4 items-center w-[426px]">
        <div className="max-w-[112px] max-h-[112px]">
          <img
            ref={avatarRef}
            src={avatar}
            alt="Company logo"
            onError={handleChangeErrorImage}
          />
        </div>
        <div>
          <div className="text-gray-900 text-xl font-bold">{email}</div>
          <div className="text-gray-500 text-base font-normal">
            {companyName}
          </div>
        </div>
      </div>
      <div className="flex bg-gray-400 h-[78px] w-[1px]"></div>
      <div className="flex px-6">
        <div>
          <div className="pb-2 text-gray-700 leading-tight text-xl font-bold">
            {plan.price.includes('Free')
              ? 'You are using a free version'
              : plan.name}
          </div>
          <div className="text-gray-400 text-xs font-medium">
            {plan.price.includes('Free') ? (
              `Up to ${withPromo ? '50 000' : '30 000'} personalizations`
            ) : (
              <>
                Status <div className="text-green-400">Active</div>
              </>
            )}
          </div>
        </div>
        {!plan.price.includes('Free') && (
          <div className="ml-[105px]">
            <div className="pb-2.5 pt-1.5 text-gray-700 leading-none text-base font-medium">
              {plan.price}
            </div>
            <div className="text-gray-400 text-xs font-medium">
              Next charge
              <div className="text-gray-700">
                {format(Date.parse(plan.next_charge), 'MMMM d, yyyy')}
              </div>
            </div>
          </div>
        )}
      </div>
    </ProfileSection>
  );
};

export default UserCard;
