import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Spinner } from '@pipedataai/storybook';
import {
  NextArrow,
  SaveBlueArrow,
} from '@pipedataai/storybook/dist/components/icons/icons';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setPreviewStep } from 'src/store/slices/storageSlice';
import { useLazyStopGenerationQuery } from 'src/store/services/open-ai-generation/endpoints/open-ai-generation';

interface ICreateProp {
  handleNextStep: () => void;
  disabledNextButton: boolean;
  onDraftSave: () => void;
  nextButtonLoading?: boolean;
  saveTitle?: string;
}

const CreateCampaignFooter = ({
  handleNextStep,
  disabledNextButton,
  onDraftSave,
  nextButtonLoading,
  saveTitle,
}: ICreateProp) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [stopGeneration] = useLazyStopGenerationQuery();
  const { campaignStep } = useAppSelector((state) => state.storageReducer);
  const { campaignID, generationType, totalAds, currentAds } = useAppSelector(
    (state) => state.createCampaignReducer
  );

  const handlerPrevStep = () => {
    if (!pathname.includes('update')) {
      if (campaignStep === 3 && campaignID && totalAds !== currentAds) {
        stopGeneration({
          campaign_id: campaignID,
          generation_type: generationType,
        });
      }
    }
    navigate(`?step=${campaignStep - 1}`);
    dispatch(setPreviewStep());
  };

  return (
    <div className="flex items-center mt-[24px]">
      <Button
        baseType="outline"
        baseTitle={saveTitle || 'Save as Draft'}
        onClick={onDraftSave}
        Icon={<SaveBlueArrow className="mr-2" />}
      />
      {campaignStep !== 1 && (
        <Button
          baseType="outline"
          baseTitle="Back"
          onClick={handlerPrevStep}
          Icon={<NextArrow className="mr-2 rotate-[180deg]" color="#1A56DB" />}
          className="mr-[20px]"
        />
      )}
      <Button
        baseType="default"
        baseTitle={campaignStep === 4 ? 'Publish' : 'Next'}
        onClick={() => handleNextStep()}
        reverse
        Icon={
          nextButtonLoading ? (
            <Spinner className="ml-2.5" size="h-3 w-3" color="fill-blue-500" />
          ) : (
            <NextArrow
              className={`${campaignStep === 4 && '-rotate-90'} ml-2`}
              color={disabledNextButton ? '#1F2A37' : '#FFFFFF'}
            />
          )
        }
        disabled={disabledNextButton}
      />
    </div>
  );
};

export default CreateCampaignFooter;
