import { useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { Input } from '@pipedataai/storybook';
import { Trash } from '@pipedataai/storybook/dist/components/icons/icons';

import { useAppDispatch } from 'src/store/hooks';
import { changeDomainUrl } from 'src/store/slices/clientDomainSlice';

type CheckDomainUrlStatus = 'active' | 'enactive' | 'disabled';

interface IDomainInputProp {
  id: string;
  url: string;
  status: CheckDomainUrlStatus;
  onDeleteSubdomain: (id: string, url: string) => void;
  isLast: boolean;
}

const DomainInput = ({
  id,
  url,
  status,
  onDeleteSubdomain,
  isLast,
}: IDomainInputProp) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(url);

  const statusStyle = useMemo(() => {
    return status === 'active'
      ? 'bg-green-400'
      : status === 'disabled'
      ? 'bg-red-500'
      : 'bg-gray-400';
  }, [status]);

  const debouncedChangeDomainUrl = debounce((url) => {
    dispatch(changeDomainUrl({ id, url }));
  }, 500);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    debouncedChangeDomainUrl(e.target.value);
  };

  return (
    <label className="w-full flex items-center" htmlFor={`domain-input-${id}`}>
      <Input
        className="w-full [&>div>div]:max-w-none [&>div>div>input]:max-w-none"
        id={`domain-input-${id}`}
        value={value}
        onChange={handleChange}
      />
      <div
        className={`max-h-[14px] max-w-[14px] min-h-[14px] min-w-[14px] ${statusStyle} rounded-full mx-3.5`}
      />
      {!isLast && (
        <button onClick={() => onDeleteSubdomain(id, url)}>
          <Trash />
        </button>
      )}
    </label>
  );
};

export default DomainInput;
