import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from '@pipedataai/storybook/';

import { parseResponse } from 'src/store/services/helpers';
import { useGetCampaignVersionsQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { CampaignVersions } from 'src/store/services/create-campaign/types';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setFilters } from 'src/store/slices/campaignSlice';

interface IGetVersions {
  campaignID: string;
  onChangeVersion: (version: string) => void;
  allPages?: boolean;
}

const useGetCampaignVersions = ({
  campaignID,
  onChangeVersion,
  allPages,
}: IGetVersions) => {
  const dispatch = useAppDispatch();
  const { data, error } = useGetCampaignVersionsQuery(campaignID, {
    skip: allPages,
  });
  const { result, errorMessage } = parseResponse({ data, error });

  const { filters } = useAppSelector((state) => state.createCampaignReducer);
  const [versionsState, setVersions] = useState<CampaignVersions[]>(
    result ? result.data : []
  );
  const [selectedVersion, setSelectedVersion] = useState<
    SelectOption | undefined
  >(
    filters.versionID
      ? {
          label: `ver. ${filters.versionID}`,
          value: filters.versionID,
        }
      : undefined
  );
  const [versionError, setVersionError] = useState('');

  const versions = useMemo(() => {
    const versionArr = [];
    versionArr.push({ label: 'All', value: '' });

    if (result) {
      result.data.forEach((item) => {
        versionArr.push({
          label: `ver. ${item.id}`,
          value: item.id,
        });
      });
    }

    return versionArr;
  }, [result]);

  const handleSelectVersion = (versionID: string) => {
    const currentVersion = versions.find((ver) => ver.value === versionID);
    const versionDate = versionsState.find((ver) => ver.id === versionID);

    if (versionID) {
      setSelectedVersion(currentVersion);
      onChangeVersion(versionID);
    } else {
      setSelectedVersion(undefined);
      onChangeVersion('');
    }
    dispatch(
      setFilters({
        endDate: versionDate?.created_at || '',
        startDate: versionDate?.last_updated_at || '',
        versionID,
      })
    );
  };

  useEffect(() => {
    if (error && errorMessage) {
      setVersionError(errorMessage);
    }
    if (result) {
      setVersions(result.data);
    }
  }, [result, error, errorMessage]);

  return {
    versions,
    selectedVersion,
    error: versionError,
    handleSelectVersion,
  };
};

export default useGetCampaignVersions;
