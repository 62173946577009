import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '@pipedataai/storybook';
import { useParseUrl as getParseUrl } from '@pipedataai/storybook';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setMarkupIframeLink,
  setMarkupList,
  setMarkupDeleteElementById,
  setMarkupDeleteElementByPath,
  setMarkupLink,
} from 'src/store/slices/markup-editor-slice';
import {
  setSelectedElements,
  setDeleteElementById,
  setDeleteElementByPath,
  setCampaignSiteUrl,
} from 'src/store/slices/campaignSlice';
import { IElement } from 'src/store/services/create-campaign/types';
import {
  useCheckRedirectedUrlMutation,
  useLazyGetCampainDataQuery,
} from 'src/store/services/create-campaign/endpoints/create-campaign';
import { parseResponse } from 'src/store/services/helpers';

import { getSelectedElementPath } from './hooks/getSelectedElementPath';
import { getSelectedElementValue } from './hooks/getSelectedElementValue';
import { getElementActionName } from './hooks/getActionElementName';
import setSelectElements from './hooks/setSelectElements';
import {
  getTypeElement,
  handleMouseOut,
  handleMouseOver,
} from './hooks/getElementByHover';
import { handlePreSelectElement } from './hooks/getElementByClick';

import Header from './blocks/header';
import Iframe from './blocks/iframe';
import IframeModalBox from './blocks/modal-box';
import {
  clickableWindow,
  createOverlay,
  positionOverlay,
  removeOverlay,
  windowTitle,
} from './blocks/selected-window';

const PROXY = process.env.REACT_APP_PROXY_URL || 'custom-proxy';

const MarkupEditor = ({
  byElements,
  limitElements,
  handleSaveAndExit,
  skipTitle,
  title,
  headerSelectName,
  headerSetElementName,
  isConversionActions,
}: {
  byElements?: string[];
  limitElements?: number;
  handleSaveAndExit?: () => void;
  skipTitle?: boolean;
  title?: string;
  headerSelectName?: string;
  headerSetElementName?: string;
  isConversionActions?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { iframeUrl, link, list } = useAppSelector(
    (state) => state.markupEditorReducer
  );
  const [checkRedirectedUrl] = useCheckRedirectedUrlMutation();
  const [getCampainData] = useLazyGetCampainDataQuery();
  const [iframeError, setIframeError] = useState('');
  const [scriptLoading, setScriptLoading] = useState(true);
  const [selectedElement, setSelectedElement] = useState<any>(undefined);
  const [selectedElementModal, setSelectedElementModal] =
    useState<boolean>(false);
  const [iframeLoad, setIframeLoad] = useState(false);
  const [pointerEventsRemoved, setPointerEventsRemoved] = useState(true);
  const [hrefRemoved, setHrefRemoved] = useState(true);

  const removePointerEvents = useCallback((doc: any) => {
    const elementsWithPointerEvents = doc.querySelectorAll(
      '*[style*="pointer-events"]'
    );
    elementsWithPointerEvents.forEach((element: any) => {
      element.style.pointerEvents = '';
    });
    const elements = doc.querySelectorAll('[data-framer-generated] *');
    elements.forEach((element: any) => {
      const isMyElement = element.hasAttribute('marked-button');
      if (!isMyElement) {
        element.style.pointerEvents = 'auto';
      }
    });
  }, []);

  const removeHrefEvents = useCallback((doc: any) => {
    const hrefElements = doc.querySelectorAll('a');
    hrefElements.forEach((element: any) => {
      const clone = element.cloneNode(true);
      element.parentNode.replaceChild(clone, element);
    });
  }, []);

  const getIframeData = useCallback(async () => {
    const response = await checkRedirectedUrl(link);
    const { result, errorMessage } = parseResponse(response);
    if (errorMessage) {
      setIframeError(errorMessage);
    }
    if (result && !errorMessage) {
      dispatch(setMarkupIframeLink(result.url));
    }
  }, [checkRedirectedUrl, dispatch, link]);

  const handleAddedElement = useCallback(
    (element: any, elementName?: string) => {
      const selectElement = element;
      const elementPath = getSelectedElementPath(selectElement);
      const elementValue = getSelectedElementValue(selectElement);
      const elementType = getTypeElement(selectElement);
      const elementUrl = selectElement.href
        ? getParseUrl(selectElement.href, PROXY)
        : '';
      const saveElement = {
        name: elementName ?? getElementActionName(selectElement),
        path: elementPath,
        default_value: elementValue,
        tag_type: elementType,
        default_url: elementUrl ?? '',
      };
      selectElement.setAttribute(
        'selected',
        `${elementName ?? getElementActionName(selectElement)}`
      );

      const overlay = createOverlay(
        '#FACA15',
        elementName ?? getElementActionName(selectElement)
      );

      if (elementName) {
        const rect = selectElement.getBoundingClientRect();
        const newText = windowTitle(elementName);
        const elementWindow = clickableWindow(
          newText,
          rect.top + rect.height + window.frames[0].scrollY + 'px',
          rect.right + window.frames[0].scrollX,
          elementName
        );

        selectElement.style.position = 'relative';
        window.frames[0].document.body.appendChild(elementWindow);
      }

      positionOverlay(overlay, selectElement);
      overlay.style.display = 'block';
      selectElement.removeAttribute('pre-select');
      removeOverlay('pre-select');
      dispatch(setMarkupList([...list, saveElement]));
      setSelectedElement(element);
    },
    [dispatch, list]
  );

  const handleDeleteSelectedElement = (element: IElement) => {
    try {
      const deletedElement: any = window.frames[0].document.querySelector(
        `[selected="${element.name}"]`
      );
      if (deletedElement) {
        removeOverlay(element.name);
        deletedElement.removeAttribute('selected');
        deletedElement.style.position = null;
        deletedElement.style.boxShadow = null;
      }
      const elementDescription = Array.from(
        window.frames[0].document.querySelectorAll('[marked-button]')
      );

      const markedElements = elementDescription.filter(
        (item) => item.getAttribute('marked-button') === element.name
      );
      if (markedElements.length > 0) {
        markedElements.forEach((item) => {
          item.remove();
        });
      }
    } catch (e) {
      // probably was not found selector
    }

    if (element.id) {
      dispatch(setDeleteElementById(element.id));
      dispatch(setMarkupDeleteElementById(element.id));
    } else {
      if (element.path) {
        dispatch(setDeleteElementByPath(element.path));
        dispatch(setMarkupDeleteElementByPath(element.path));
      } else {
        const elementPath = getSelectedElementPath(selectedElement);
        dispatch(setDeleteElementByPath(elementPath));
        dispatch(setMarkupDeleteElementByPath(elementPath));
      }
    }

    setSelectedElement(undefined);
  };

  const handleSaveAndExitEditor = (elementsArr: IElement[]) => {
    const stateUrl = location.state;
    dispatch(setSelectedElements(elementsArr));
    if (handleSaveAndExit) {
      handleSaveAndExit();
      return;
    }
    dispatch(setCampaignSiteUrl(stateUrl.selectedUrl));
    navigate(stateUrl.backUrl);
  };

  useEffect(() => {
    const campaignID = searchParams.get('campaignID');
    const markupURL = searchParams.get('url');
    if (markupURL && !isConversionActions) {
      dispatch(setMarkupLink(markupURL));
      dispatch(setMarkupIframeLink(markupURL));
    }

    if (campaignID && link === '' && !isConversionActions) {
      getCampainData(campaignID);
      setScriptLoading(true);
      setIframeError('');
    }
  }, [dispatch, getCampainData, isConversionActions, link, searchParams]);

  useEffect(() => {
    try {
      const clearUrl = encodeURIComponent(
        link && link.endsWith('/') ? link.slice(0, -1) : link
      );
      const clearFrameUrl =
        iframeUrl && iframeUrl.endsWith('/')
          ? iframeUrl.slice(0, -1)
          : iframeUrl;
      if (
        iframeLoad &&
        ((clearFrameUrl.includes(clearUrl) && clearUrl !== '') ||
          isConversionActions)
      ) {
        const frame = window[0];
        if (typeof window !== 'undefined' && frame && frame.document) {
          if (pointerEventsRemoved) {
            removePointerEvents(frame.document);
            setPointerEventsRemoved(false);
          }
          if (hrefRemoved) {
            removeHrefEvents(frame.document);
            setHrefRemoved(false);
          }
          // ### Markup editor
          frame.onload = () => {
            frame.document.onclick = (e) => {
              e.preventDefault();
              e.stopPropagation();
              handlePreSelectElement(
                e,
                selectedElement,
                (e) => setSelectedElement(e),
                byElements
              );
            };

            frame.document.onmouseover = (e) => handleMouseOver(e, byElements);
            frame.document.onmouseout = (e) => handleMouseOut(e, byElements);
          };
          setScriptLoading(false);
          // ## for conversion actions
          if (limitElements === list.length) {
            frame.document.onclick = (e) => {
              e.preventDefault();
              e.stopPropagation();
              handlePreSelectElement(
                e,
                selectedElement,
                (e) => setSelectedElement(e),
                byElements
              );
            };
            frame.document.onmouseover = null;
            frame.document.onmouseout = null;
            setScriptLoading(false);
          } else {
            frame.document.onclick = (e) => {
              e.preventDefault();
              e.stopPropagation();
              handlePreSelectElement(
                e,
                selectedElement,
                (e) => setSelectedElement(e),
                byElements
              );
            };
            frame.document.onmouseover = (e) => handleMouseOver(e, byElements);
            frame.document.onmouseout = (e) => handleMouseOut(e, byElements);
          }
          // ##
          if (frame.document.onmouseover) {
            const handleDOMContentLoaded = () => {
              setScriptLoading(false);
              frame.removeEventListener('load', handleDOMContentLoaded);
            };
            frame.addEventListener('load', handleDOMContentLoaded);
          }
        }
      }
    } catch (e) {
      console.error('MARKUP_LOAD_ERROR: ', e);
    }
  }, [
    selectedElement,
    limitElements,
    iframeLoad,
    removePointerEvents,
    pointerEventsRemoved,
    iframeUrl,
    link,
    isConversionActions,
    hrefRemoved,
    removeHrefEvents,
    handleAddedElement,
    list.length,
    byElements,
  ]);

  useEffect(() => {
    if (!selectedElement && list.length > 0 && iframeLoad) {
      if (window[0]) {
        try {
          setTimeout(() => setSelectElements(window[0].document, list), 1000);
        } catch (e) {
          console.error('MARKUP_SELECT_ELEMENTS_ERROR: ', e);
        }
      }
    }
  }, [list, selectedElement, iframeLoad]);

  useEffect(() => {
    const clearUrl = link && link.endsWith('/') ? link.slice(0, -1) : link;
    const clearFrameUrl =
      iframeUrl && iframeUrl.endsWith('/') ? iframeUrl.slice(0, -1) : iframeUrl;
    if (
      (clearUrl !== '' && clearFrameUrl.includes(clearUrl)) ||
      isConversionActions
    ) {
      getIframeData();
    }
  }, [getIframeData, iframeUrl, isConversionActions, link]);

  return (
    <div className="relative">
      <Header
        title={
          title
            ? title
            : 'Select the elements (text, images, background images, buttons) that will change on your website'
        }
        selectName={headerSelectName}
        setElementName={headerSetElementName}
        selectedItems={list.filter((e: any) => !e.deletion)}
        haveSelectItem={
          !!selectedElement && !selectedElement?.hasAttribute('selected')
        }
        onDeleteItemClick={handleDeleteSelectedElement}
        onSaveClick={(arr) => handleSaveAndExitEditor(arr)}
        onOpenSelectedModal={() => {
          if (isConversionActions) {
            handleAddedElement(selectedElement);
            return;
          }
          setSelectedElementModal(!selectedElementModal);
        }}
        selectedType={
          !isConversionActions && selectedElement
            ? getTypeElement(selectedElement)
            : undefined
        }
        isConversionActions={isConversionActions}
      />
      {scriptLoading && !iframeError && (
        <div className="absolute w-full h-[calc(100vh_-_56.4px)] flex justify-center items-center bg-[#00000080] z-10">
          <Spinner size="h-10 w-10" color="fill-blue-500" />
        </div>
      )}
      {iframeError ? (
        <div className="absolute w-full h-[calc(100vh_-_56.4px)] flex justify-center items-center bg-[#00000080] z-10">
          <p>{iframeError}</p>
        </div>
      ) : (
        <Iframe
          iframeUrl={iframeUrl}
          isConversionFrame={!!isConversionActions}
          onLoadIframe={() => setIframeLoad(true)}
        />
      )}
      <IframeModalBox
        isOpen={selectedElementModal}
        onClose={() => {
          setSelectedElementModal(false);
        }}
        element={selectedElement}
        onSave={handleAddedElement}
        skipTitle={skipTitle}
      />
    </div>
  );
};

export default MarkupEditor;
