import { FC, useEffect, useState } from 'react';
import { Button, Input } from '@pipedataai/storybook';

import { useAppSelector } from 'src/store/hooks';
import { useAddPromoMutation } from 'src/store/services/user/endpoints/user';
import { UserProfile } from 'src/store/services/user/types';

import { parseError } from 'src/store/services/helpers';

import ChangePasswordModal from './blocks/change-password-modal';
import UserCard from './blocks/user-card';
import ProfileSection from './blocks/profile-section';

const LOGO_TOKEN = process.env.REACT_APP_LOGO_DEV_TOKEN;

interface UserViewData {
  user: UserProfile;
}

const Profile: FC<UserViewData> = function ({ user }) {
  const { promo } = useAppSelector((state) => state.variablesReducer);
  const { domains } = useAppSelector((state) => state.clientDomainReducer);

  const [showModal, setShowModal] = useState(false);

  const [errorPromo, setErrorPromo] = useState('');
  const [localPromo, setLocalPromo] = useState(promo);

  const [addPromo, { isLoading, error, isSuccess }] = useAddPromoMutation();

  const handlePromoChange = (e: any) => {
    setErrorPromo('');
    setLocalPromo(e.target.value);
  };

  const handleAddPromo = () => {
    setErrorPromo('');
    addPromo({ promo_code: localPromo });
  };

  useEffect(() => {
    if (error) {
      const { errorMessage } = parseError(error);
      setErrorPromo(errorMessage);
    }
  }, [error]);

  return (
    <div className="flex flex-col gap-y-8">
      <UserCard
        avatar={
          domains.length > 0
            ? `https://img.logo.dev/${domains[0].url.replace(
                /^https?:\/\//,
                ''
              )}?size=200&format=png&token=${LOGO_TOKEN}`
            : `/companies/company_1.png`
        }
        email={user.email}
        companyName={user.company_name}
        plan={user.sub}
        withPromo={!!promo}
      />
      <ProfileSection className="[&]:items-end">
        <div className="max-w-[437px] w-full">
          <div className="max-w-[437px] mb-4">
            <Input
              id="userEmail"
              type="email"
              title="Email"
              placeholder={user.email}
              className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none [&>div>div>input]:py-2.5"
              disabled={true}
            />
          </div>
          <div className="flex items-end">
            <div className="max-w-[437px] w-full">
              <Input
                id="userPassword"
                title="Password"
                type="password"
                placeholder="****************"
                className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none [&>div>div>input]:py-2.5"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <Button
          className="min-w-[147px] w-[147px] ml-1 py-2.5 whitespace-nowrap"
          onClick={() => setShowModal(true)}
          baseTitle="Change password"
        />
      </ProfileSection>
      <ProfileSection className="[&]:items-end">
        <div className="max-w-[437px] w-full">
          <Input
            id="promo"
            type="text"
            title="Promo code"
            placeholder="Enter code"
            className={`[&>div>div]:max-w-none [&>div>div>input]:max-w-none [&>div>div>input]:py-2.5 ${
              promo ? '[&&>div>div>input]:bg-white [&&]:text-gray-900' : ''
            }`}
            value={localPromo || promo}
            onChange={handlePromoChange}
            error={errorPromo}
            success={isSuccess ? 'Promo code applied successfully!' : ''}
            disabled={!!promo}
          />
        </div>
        <Button
          className={`min-w-[147px] w-[147px] justify-center ml-1 py-2.5 ${
            promo ? '[&&]:bg-primary-700 [&&]:text-white' : ''
          } `}
          onClick={promo ? () => null : handleAddPromo}
          isLoading={isLoading}
          disabled={!!promo}
          baseTitle="Apply"
        />
      </ProfileSection>
      <ChangePasswordModal open={showModal} onClose={setShowModal} />
    </div>
  );
};

export default Profile;
