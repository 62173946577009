import { ResponseWrap } from '../../response_wrap';
import { variablesAPI } from '..';
import { ClearbitVariablesType, DynamicObject } from '../types';

export const variablesApi = variablesAPI.injectEndpoints({
  endpoints: (builder) => ({
    getClearbitVariables: builder.query<
      ResponseWrap<ClearbitVariablesType>,
      void
    >({
      query: () => ({
        url: 'api/variables/clearbit',
      }),
    }),
    getClearbitCompanies: builder.query<
      ResponseWrap<DynamicObject[]>,
      string | undefined
    >({
      query: (segmentID) => ({
        url: `api/variables/clearbit/companies${
          segmentID ? `?segmentID=${segmentID}` : ''
        }`,
      }),
    }),
    getClearbitCompaniesCount: builder.query<ResponseWrap<any>, any>({
      query: (body) => ({
        url: `api/variables/clearbit/companies/count`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useGetClearbitVariablesQuery,
  useGetClearbitCompaniesQuery,
  useLazyGetClearbitCompaniesCountQuery,
} = variablesApi;
