import { useEffect, useMemo, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { HiExclamation } from 'react-icons/hi';
import { GroupSelect, SimpleSelect } from '@pipedataai/storybook';
import { DropDownArrow } from '@pipedataai/storybook/dist/components/icons/icons';

import { setFilters } from 'src/store/slices/campaignSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { DetailCampaignAd } from 'src/store/services/create-campaign/types';

import { DEVICE_ITEMS } from 'src/features/detail-campaign/constants';

import CalendarComponent from 'src/components/calendar';
import { customToastMessage } from 'src/components/toast';

import useGetAdsVersions from '../hooks/useGetAdsVersions';
import useGetCampaignVersions from '../hooks/useGetCampaignVersions';
import useGetCampaignConversions from '../hooks/useGetCampaignConversions';
import useTimeInterval from '../hooks/useTimeInterval';

interface IPicker {
  allPages: boolean;
  campaignID: string;
  onChangeDate: (startDate: Date, endDate: Date) => void;
  onChangeVersion: (version: string) => void;
  onChangeAds: (adID: string[]) => void;
  onChangeDevice: (deviceValue: string) => void;
  onChangeConversions: (conversion: string) => void;
  onChangeTimeInterval: (value: string) => void;
  lastCampaignDate: Date;
  ads: DetailCampaignAd[];
  disabled?: boolean;
}

const VersionPicker = ({
  allPages,
  campaignID,
  onChangeVersion,
  onChangeDate,
  onChangeAds,
  onChangeDevice,
  onChangeConversions,
  onChangeTimeInterval,
  lastCampaignDate,
  ads,
  disabled,
}: IPicker) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.createCampaignReducer);

  const today = useMemo(
    () => (filters.endDate ? new Date(filters.endDate) : new Date()),
    [filters.endDate]
  );
  const last_month = useMemo(
    () => new Date(new Date().setDate(today.getDate() - 30)),
    [today]
  );

  const { versions, selectedVersion, error, handleSelectVersion } =
    useGetCampaignVersions({
      campaignID,
      onChangeVersion,
      allPages,
    });

  const { adsVersion, selectedAdsVersion, handleSelectAds } = useGetAdsVersions(
    { ads: ads, onChangeAds }
  );

  const { conversions, selectedConversion, handleSelectConversion } =
    useGetCampaignConversions({
      campaignID,
      onChangeVersion: onChangeConversions,
      allPages,
    });

  const { intervalItems, intervalValue, handleSelectTimeInterval } =
    useTimeInterval({
      handleChangeInterval: onChangeTimeInterval,
    });
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const [startDate, setStartDate] = useState(
    filters.startDate ? new Date(filters.startDate) : last_month
  );
  const [endDate, setEndDate] = useState(today);

  const handleChangeDate = (event: any) => {
    if (event.startDate !== event.endDate) {
      dispatch(
        setFilters({
          startDate: Intl.DateTimeFormat('en-US').format(event.startDate),
          endDate: Intl.DateTimeFormat('en-US').format(event.endDate),
          versionID: '',
        })
      );
      onChangeDate(event.startDate, event.endDate);
      setStartDate(event.startDate);
      setEndDate(event.endDate);
      setShowCalendar(false);
    }
  };

  const handleSelectDevice = (value: string) => {
    dispatch(setFilters({ ...filters, is_desktop: value }));
    onChangeDevice(value);
  };

  useEffect(() => {
    if (!filters.endDate) {
      setStartDate(last_month);
      setEndDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      customToastMessage(
        error,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
    }
  }, [error]);

  return (
    <div>
      <div className="flex text-base font-normal text-gray-700 relative items-center">
        <p className="mt-5 mr-3 text-xs font-medium text-gray-900">
          Show stats for
        </p>
        {!allPages && (
          <>
            <GroupSelect
              title="Ads"
              items={[
                { groupName: 'All', groupItems: [adsVersion[0]] },
                { groupName: 'Enabled', groupItems: adsVersion.slice(1) },
              ]}
              value={
                selectedAdsVersion?.map((ad) => ad.label).join(', ') || 'All'
              }
              onSelect={(selectedID) => handleSelectAds(selectedID)}
              baseClassName="max-w-[200px] [&>label]:text-gray-500 [&>label]:text-xs [&>label]:font-normal [&>div>input]:pr-8 [&>div>input]:text-ellipsis"
              popupClassName="mt-2 min-w-max rounded-lg"
              itemClassName="[&]:text-sm [&]:font-normal [&]:text-gray-700"
              disabled={adsVersion.length === 0 || disabled}
            />
            <SimpleSelect
              title="Conversion Actions"
              items={conversions}
              value={selectedConversion?.label || 'All'}
              onSelect={(selectedID) => handleSelectConversion(selectedID)}
              baseClassName="max-w-[200px] mx-3 [&>label]:text-gray-500 [&>label]:text-xs [&>label]:font-normal [&>div>input]:pr-8 [&>div>input]:text-ellipsis"
              itemClassName="[&]:text-sm [&]:font-normal [&]:text-gray-700"
              disabled={versions.length === 0 || disabled}
            />
            <SimpleSelect
              title="Device"
              items={DEVICE_ITEMS}
              value={
                DEVICE_ITEMS.find((item) => item.value === filters.is_desktop)
                  ?.label || DEVICE_ITEMS[0].label
              }
              onSelect={(selectedID) => handleSelectDevice(selectedID)}
              baseClassName="max-w-[140px] [&>label]:text-gray-500 [&>label]:text-xs [&>label]:font-normal"
              itemClassName="[&]:text-sm [&]:font-normal [&]:text-gray-700"
              disabled={disabled}
            />
            <SimpleSelect
              title="Version"
              items={versions}
              value={selectedVersion?.label || 'Select'}
              onSelect={(selectedID) => handleSelectVersion(selectedID)}
              baseClassName="max-w-[140px] mx-3 [&>label]:text-gray-500 [&>label]:text-xs [&>label]:font-normal"
              itemClassName="[&]:text-sm [&]:font-normal [&]:text-gray-700"
              disabled={versions.length === 0 || disabled}
            />
          </>
        )}
        <div className="max-w-[215px]">
          <p className="mb-2 text-xs font-normal text-gray-500">Date range</p>
          <div className="flex items-center">
            <div className="relative flex items-center w-full">
              <div className="absolute top-2/4 -translate-y-2/4 right-0 flex items-center pr-3 pointer-events-none">
                <DropDownArrow />
              </div>
              <input
                type="text"
                onClick={() => setShowCalendar(!showCalendar)}
                readOnly={true}
                placeholder="Last 30 days"
                value={
                  differenceInDays(endDate, startDate) !== 30
                    ? `${Intl.DateTimeFormat('en-US').format(
                        startDate
                      )} - ${Intl.DateTimeFormat('en-US').format(endDate)}`
                    : 'Last 30 days'
                }
                className="pr-9 cursor-pointer text-left border border-gray-300 text-gray-900 text-sm font-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ml-0 py-2 px-4 pl-3 pr-6"
                disabled={disabled}
              />
              {showCalendar && (
                <CalendarComponent
                  lastDate={lastCampaignDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleChangeDate}
                  onClose={(e) => {
                    if (e.startDate !== '' && e.endDate !== '') {
                      setShowCalendar(false);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!allPages && (
          <SimpleSelect
            title="Time interval"
            items={intervalItems}
            value={intervalValue.label}
            onSelect={(selectedID) => handleSelectTimeInterval(selectedID)}
            baseClassName="max-w-[140px] mx-3 [&>label]:text-gray-500 [&>label]:text-xs [&>label]:font-normal"
            itemClassName="[&]:text-sm [&]:font-normal [&]:text-gray-700"
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default VersionPicker;
