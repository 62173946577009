import {
  createOverlay,
  positionOverlay,
  removeOverlay,
} from '../blocks/selected-window';
import {
  checkElementsList,
  checkParentsElementsList,
  hasTextInChildren,
} from './getElementByHover';

export const findMatchingParent = (byElements: string[], element: any): any => {
  let currentElement = element;

  while (currentElement) {
    const tagName = currentElement.tagName?.toLowerCase();
    const argType = currentElement.getAttribute('type');
    const isCanSelect = byElements.find(
      (item) => item === tagName || (argType && item.includes(argType))
    );

    if (isCanSelect) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return element;
};

const selectedElements = (
  element: any,
  setElementSelect: (element: any) => void
) => {
  const overlay = createOverlay('#3F83F8', 'pre-select');
  element.setAttribute('pre-select', 'true');
  positionOverlay(overlay, element);
  overlay.style.display = 'block';
  setElementSelect(element);
};

export const handlePreSelectElement = (
  e: any,
  selectedElement: any,
  setElementSelect: (element: any) => void,
  byElements?: string[]
) => {
  const element = e.target;
  // Removing the selected element on a second click
  if (selectedElement && selectedElement.hasAttribute('pre-select')) {
    selectedElement.style.boxShadow = null;
    selectedElement.removeAttribute('pre-select');
    removeOverlay('pre-select');
    setElementSelect(null);
  }

  // Do nothing if the element is added
  if (selectedElement && selectedElement.hasAttribute('select')) {
    return;
  }
  // Selecting the active element
  if (selectedElement !== element) {
    const elementBackground = window
      .getComputedStyle(element)
      .getPropertyValue('background-image');
    const hasBackgroundImage = elementBackground.includes('url');

    const hasChildren = element.children.length > 0;
    const hasTextContent = hasTextInChildren(element, true);
    const actionElement =
      byElements && byElements?.length > 0
        ? byElements?.includes(element.tagName.toLowerCase())
        : false;

    if (hasBackgroundImage || !hasChildren || hasTextContent || actionElement) {
      if (byElements) {
        const checkElement = checkElementsList(byElements, element);
        if (!checkElement) {
          const parents = checkParentsElementsList(byElements, element);
          if (parents) {
            const parentElement = findMatchingParent(byElements, element);
            selectedElements(parentElement, setElementSelect);
          }
        }

        if (checkElement && !byElements.includes('form')) {
          selectedElements(element, setElementSelect);
        }

        if (byElements.includes('form')) {
          const formInElement = element.querySelector('form');
          if (formInElement) {
            selectedElements(formInElement, setElementSelect);
          } else {
            const formOutElement = element.closest('form');
            selectedElements(formOutElement, setElementSelect);
          }
        }
      } else {
        selectedElements(element, setElementSelect);
      }
    }
  } else {
    setElementSelect(undefined);
  }
};
