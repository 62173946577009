import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HiExclamation, HiCheckCircle } from 'react-icons/hi';
import { CheckboxSelect, Input, ModalBox } from '@pipedataai/storybook';
import { CursorClick } from '@pipedataai/storybook/dist/components/icons/icons';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setSelectedElements,
  setDeleteElementById,
  setDeleteElementByPath,
  setIsDeletionDone,
  setGenerationType,
} from 'src/store/slices/campaignSlice';
import {
  setMarkupDeleteElementByPath,
  setMarkupDeleteElementById,
  setMarkupLink,
  setMarkupIframeLink,
} from 'src/store/slices/markup-editor-slice';
import {
  setAiLoader,
  setGlobalLoading,
  setNextStep,
} from 'src/store/slices/storageSlice';
import {
  useGetCampainDataQuery,
  useLazyGetCampainDataQuery,
} from 'src/store/services/create-campaign/endpoints/create-campaign';
import { useLazyHandleGenerationProcessQuery } from 'src/store/services/open-ai-generation/endpoints/open-ai-generation';

import { customToastMessage } from 'src/components/toast';

import useSaveAsDraft from './hooks/useSaveAsDraft';
import CreateCampaignFooter from './blocks/create-campaign-footer';
import { useSelectAdsUrl } from './hooks/useSelectAdsUrl';
import AiMethodCard from './blocks/ai-method-card';
import { AI_GENERATION_INFO } from './constants';
import { parseURL } from '../markup-editor/hooks/getSelectedElementValue';

const StepTwo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();

  const { campaignID, siteUrl, elements, isDeletionDone } = useAppSelector(
    (state) => state.createCampaignReducer
  );

  const [aiGenerationMethod, setAiGenerationMethod] = useState('first');
  const [confirmModal, setConfirmModal] = useState<number | undefined>();
  const [nextLoad, setNextLoad] = useState(false);

  useGetCampainDataQuery(id as string, { skip: !id || siteUrl !== '' });
  const [getCamapgins] = useLazyGetCampainDataQuery();

  const { handleSave, saveError } = useSaveAsDraft();
  const [handleGenerationProcess] = useLazyHandleGenerationProcessQuery();

  const { active, allValues, handleSelect } = useSelectAdsUrl();
  const isUpdateCampaign = useMemo(
    () => pathname.includes('update'),
    [pathname]
  );

  const handleGoToMarkupEditor = async () => {
    if (!siteUrl) {
      customToastMessage(
        'Please input your page url',
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }
    let href = '';
    try {
      href = new URL(siteUrl).href;
      href = parseURL(href);
    } catch (e) {
      customToastMessage(
        'Invalid url',
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }

    const actualMarkupUrl = pathname + search;
    dispatch(setMarkupLink(href));
    dispatch(setMarkupIframeLink(href));
    navigate(`/markup-editor?campaignID=${campaignID}&url=${href}`, {
      state: { backUrl: actualMarkupUrl, selectedUrl: new URL(siteUrl).href },
    });
  };

  const handleChangeElementName = (text: string, index: number) => {
    const newElementsArr = elements.slice();
    newElementsArr[index] = {
      ...newElementsArr[index],
      name: text,
    };
    dispatch(setSelectedElements(newElementsArr));
  };

  const handleClearElementName = (index: number) => {
    const newElementsArr = elements.slice();
    newElementsArr[index] = {
      ...newElementsArr[index],
      name: '',
    };
    dispatch(setSelectedElements(newElementsArr));
  };

  const handleDeletedElement = (elementIndex: number) => {
    const elementID = elements[elementIndex].id;
    if (elementID) {
      dispatch(setMarkupDeleteElementById(elementID));
      dispatch(setDeleteElementById(elementID));
    } else {
      const path = elements[elementIndex].path;
      dispatch(setDeleteElementByPath(path));
      dispatch(setMarkupDeleteElementByPath(path));
    }
    setConfirmModal(undefined);
  };

  const handleNextStep = async () => {
    setNextLoad(true);
    await handleSave();
    if (!isUpdateCampaign) {
      dispatch(setAiLoader(true));
      if (campaignID && aiGenerationMethod !== 'manual') {
        dispatch(setGlobalLoading(true));
        dispatch(setGenerationType(aiGenerationMethod));
        await handleGenerationProcess({
          campaign_id: campaignID,
          generation_type: aiGenerationMethod,
        });
      }
    }
    if (!saveError) {
      navigate('?step=3');
      dispatch(setNextStep());
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setNextLoad(false);
  };

  const handleSaveAsDraft = async () => {
    dispatch(setAiLoader(false));
    dispatch(setGlobalLoading(true));
    const campaign = await handleSave();
    if (campaign && !saveError) {
      getCamapgins(campaign.id);
      dispatch(setGlobalLoading(false));
      customToastMessage(
        `Campaign successfully ${campaignID ? 'updated' : 'created'}.`,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }
    if (isUpdateCampaign) {
      dispatch(setGlobalLoading(false));
      navigate('/campaigns');
    }
  };

  const handleGoToCompanyPage = (e: any) => {
    const ctrlClicked = sessionStorage.getItem('ctrlDown');
    if (ctrlClicked === null || ctrlClicked !== 'true') {
      e.preventDefault();
      navigate(`/company-info`);
    }
  };

  useEffect(() => {
    window.document.onkeydown = (event) => {
      if (event.ctrlKey || event.metaKey) {
        sessionStorage.setItem('ctrlDown', 'true');
      }
    };
    window.document.onkeyup = (event) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        sessionStorage.setItem('ctrlDown', 'false');
      }
    };
  }, []);

  useEffect(() => {
    if (isDeletionDone) {
      handleSave();
      dispatch(setIsDeletionDone());
    }
  }, [dispatch, handleSave, isDeletionDone]);

  return (
    <>
      <div className="flex-1 flex flex-col items-start justify-between mt-12">
        <div className="w-full">
          <div className="w-10/12 bg-white p-8 rounded-lg">
            <p className="text-lg font-semibold text-gray-900">
              Select page and elements for personalization
            </p>
            <p className="mt-2 mb-4 w-3/4 text-sm font-normal text-gray-700">
              Select the page for personalization, then click &quot;Open Editor
              and Select Elements&quot; button.
              <br /> Once the page loads, choose the elements you want to change
              based on the ad and/or segment.
            </p>
            <div className="flex">
              <CheckboxSelect
                type="radio"
                className="max-w-[526px] w-full"
                popupClassName="[&>li>label]:items-center [&>li>label>div>div>p]:font-medium [&>li>label>div>div>p]:leading-none"
                activeValues={active}
                selectElements={allValues}
                onSelect={handleSelect}
              />
              <button
                className="flex self-end items-center py-2 px-3 ml-8 bg-[#1A56DB] border border-[#1A56DB] rounded-lg text-sm font-medium text-[#FFFFFF] whitespace-nowrap"
                type="button"
                onClick={handleGoToMarkupEditor}
              >
                {siteUrl
                  ? 'Open Editor and Select Elements'
                  : 'Start to markup'}
                <CursorClick className="ml-2" />
              </button>
            </div>
          </div>
          <div className="mt-6 w-10/12 bg-white p-8 rounded-lg">
            <p className="text-lg font-semibold text-gray-900">
              Name personalization elements
            </p>
            <p className="mt-2 mb-4 text-sm font-normal text-gray-700">
              Assign titles to selected elements for easy identification and
              content generation by our AI.
            </p>
            {elements.filter((e) => !e.deletion).length > 0 && (
              <div className="w-[55%]">
                {elements.map((element, index) => (
                  <div
                    key={`${element.path}-${index}`}
                    className="mt-4 flex items-center justify-between"
                  >
                    <p className="flex-none text-sm font-medium text-gray-900 capitalize">
                      {(`${element.tag_type}` === '1' && 'text') ||
                        (`${element.tag_type}` === '2' && 'button') ||
                        (`${element.tag_type}` === '3' && 'image') ||
                        element.tag_type}
                    </p>
                    <Input
                      id={`selected-element-${element.name}`}
                      value={element.name}
                      className={`w-[calc(100%_-_75px)] [&>div>div]:max-w-full [&>div>div>input]:max-w-full ${
                        element.deletion && 'hidden'
                      }`}
                      onDeletedClick={() => setConfirmModal(index)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangeElementName(e.target.value, index)
                      }
                      onClearClick={() => handleClearElementName(index)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          {!isUpdateCampaign && (
            <div className="mt-6 w-10/12 bg-white p-8 rounded-lg">
              <p className="text-lg font-semibold text-gray-900">
                Select how you want your content created
              </p>
              <p className="mt-2 mb-4 w-3/4 text-sm font-normal text-gray-700">
                Start from scratch or use proven AI playbooks for effective
                content creation for your selected elements. Generated content
                is based on the information about your company, which you can
                edit on the{' '}
                <button onClick={handleGoToCompanyPage}>
                  <a href="/company-info" className="underline">
                    Company page -&gt;
                  </a>
                </button>
                .
              </p>
              <div className="flex justify-between" style={{ gap: '18px' }}>
                {AI_GENERATION_INFO.map((card) => (
                  <AiMethodCard
                    key={card.id}
                    id={card.id}
                    title={card.title}
                    description={card.description}
                    icon={card.icon}
                    checked={aiGenerationMethod === card.id}
                    handleCheck={(id) => setAiGenerationMethod(id)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <CreateCampaignFooter
          handleNextStep={() => handleNextStep()}
          disabledNextButton={elements.filter((e) => !e.deletion).length <= 0}
          onDraftSave={handleSaveAsDraft}
          nextButtonLoading={nextLoad}
          saveTitle={isUpdateCampaign ? 'Save and Exit' : undefined}
        />
      </div>
      {typeof confirmModal === 'number' && (
        <ModalBox
          isOpen={typeof confirmModal === 'number'}
          windowClassName="max-w-md"
          onClose={() => setConfirmModal(undefined)}
        >
          <ModalBox.Body>
            <p className="mb-4 text-base font-bold text-[#111928]">
              Are you sure you want to delete this element?{' '}
            </p>
            <div className="flex justify-end">
              <button
                type="button"
                className="flex items-center py-2 px-3 bg-[#c81e1e] border border-[#c81e1e] rounded-lg text-sm font-medium text-[#FFFFFF]"
                onClick={() => handleDeletedElement(confirmModal)}
              >
                Delete
              </button>
              <button
                type="button"
                className="ml-4 flex items-center py-2 px-3 bg-[#E5E7EB] border border-[#E5E7EB] rounded-lg text-sm font-medium text-[#1F2A37]"
                onClick={() => setConfirmModal(undefined)}
              >
                Cancel
              </button>
            </div>
          </ModalBox.Body>
        </ModalBox>
      )}
    </>
  );
};

export default StepTwo;
