import { useEffect, useMemo, useState } from 'react';
import { ISelectItems } from '@pipedataai/storybook/';

import { parseURL } from 'src/features/markup-editor/hooks/getSelectedElementValue';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetGoogleAdsCampaignQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { GoogleCampaigns } from 'src/store/services/create-campaign/types';
import { parseResponse } from 'src/store/services/helpers';
import {
  setCampaignSiteUrl,
  setGoogleAdsUrls,
} from 'src/store/slices/campaignSlice';

export const useSelectAdsUrl = () => {
  const dispatch = useAppDispatch();
  const { googleAdsURLs, siteUrl, googleAdsAccount, googleAdsCampaing } =
    useAppSelector((state) => state.createCampaignReducer);
  const { data, error } = useGetGoogleAdsCampaignQuery(
    { customerID: googleAdsAccount },
    {
      skip: googleAdsAccount === '',
    }
  );
  const { result } = parseResponse({ data, error });
  const [activeValues, setActiveValues] = useState<ISelectItems[]>([]);
  const allAdsUrls = useMemo(
    () =>
      googleAdsURLs.map((url) => {
        return {
          label: parseURL(url) === url ? url : parseURL(url) + ' (test)',
          value: url,
        };
      }),
    [googleAdsURLs]
  );

  const handleSelectedValue = (value: ISelectItems) => {
    setActiveValues([value]);
    dispatch(setCampaignSiteUrl(value.value));
  };

  useEffect(() => {
    if (googleAdsURLs) {
      const getActiveValues = allAdsUrls.find((item) => item.value === siteUrl);
      setActiveValues([
        {
          label: getActiveValues?.label || '',
          value: getActiveValues?.value || '',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAdsURLs, siteUrl]);

  useEffect(() => {
    if (result && result.data) {
      const { data } = result;
      const selectedGoogleCampaign = data.find(
        (googleCampaign) => googleCampaign.id === googleAdsCampaing
      ) as GoogleCampaigns;
      dispatch(
        setGoogleAdsUrls(
          selectedGoogleCampaign.extended_urls
            ? selectedGoogleCampaign.extended_urls.map((item) => item.url)
            : []
        )
      );
    }
  }, [dispatch, googleAdsCampaing, result]);

  return {
    active: activeValues,
    allValues: allAdsUrls,
    handleSelect: handleSelectedValue,
  };
};
