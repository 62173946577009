import { useState } from 'react';
import { Button, Input, ModalBox } from '@pipedataai/storybook';

import { useChangePasswordMutation } from 'src/store/services/user/endpoints/user';
import { ChangePasswordRequest } from 'src/store/services/user/types';
import { parseResponse } from 'src/store/services/helpers';

interface IChangeModal {
  open: boolean;
  onClose: (value: boolean) => void;
}

const ChangePasswordModal = ({ open, onClose }: IChangeModal) => {
  const [changePassword, { isLoading, isError, isSuccess }] =
    useChangePasswordMutation();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (confirmPassword !== newPassword) {
      setError("Passwords don't match");
      return;
    }

    if (newPassword === '') {
      setError('Password cant be empty');
      return;
    }

    if (confirmPassword === '') {
      setError('Confirm Password cant be empty');
      return;
    }

    const request: ChangePasswordRequest = {
      password: currentPassword,
      new_password: newPassword,
    };

    const changePasswordResult = await changePassword(request);
    const { result, errorMessage } = parseResponse(changePasswordResult);

    if (errorMessage) {
      setError('Password or confirm password incorrect');
      return;
    }

    if (result && !errorMessage) {
      setError('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setSuccessMessage('Password changed successfully');
      setTimeout(() => onClose(false), 1500);
    }
  };

  return (
    <ModalBox
      windowClassName="max-w-lg"
      isOpen={open}
      onClose={() => onClose(false)}
    >
      <ModalBox.Header onClose={() => onClose(false)}>
        Password information
      </ModalBox.Header>
      <ModalBox.Body className="p-8">
        <form onSubmit={handleSubmit}>
          {isSuccess && !isError && !error && (
            <div
              className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              {successMessage}
            </div>
          )}

          {(isError || error) && (
            <div
              className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              {error}
            </div>
          )}

          <Input
            id="formCurrentPassword"
            title="Current password"
            type="password"
            className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none"
            value={currentPassword}
            placeholder="Enter your current password"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <Input
            id="formNewPassword"
            title="New password"
            type="password"
            className="my-4 [&>div>div]:max-w-none [&>div>div>input]:max-w-none"
            value={newPassword}
            placeholder="Enter your new password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Input
            id="formConfirmPassword"
            title="Confirm new password"
            type="password"
            className="mb-4 [&>div>div]:max-w-none [&>div>div>input]:max-w-none"
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            baseTitle="Change password"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </form>
      </ModalBox.Body>
    </ModalBox>
  );
};

export default ChangePasswordModal;
