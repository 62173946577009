import { BreadCrumbs } from '@pipedataai/storybook';

import { useGetSyncMailQuery } from 'src/store/services/google/endpoints/google';

import Integration from 'src/features/integration/integration';
import useClearDataNavigate from 'src/helpers/useClearDataNavigate';

export function IntegrationPage() {
  const { handleNavigate } = useClearDataNavigate();
  useGetSyncMailQuery();
  return (
    <section className="h-auto">
      <div className="block items-center justify-between py-4 pt-6 px-10">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <BreadCrumbs
              items={[{ title: 'Integrations', link: '/integrations' }]}
              onNavigateClick={handleNavigate}
            />
          </div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            Integrations
          </h1>
        </div>
      </div>

      <Integration />
    </section>
  );
}
