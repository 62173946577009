import React from 'react';
import {
  HiOutlineOfficeBuilding,
  HiOutlineDuplicate,
  HiOutlineCursorClick,
} from 'react-icons/hi';
import { Dropdown, DropItem } from '@pipedataai/storybook';
import {
  CompanyPreviewIcon,
  FallbackPreviewIcon,
  PencilAlt,
} from '@pipedataai/storybook/dist/components/icons/icons';

import { IElement } from 'src/store/services/create-campaign/types';

import { AdStatus } from '../detail-campaign/blocks/ad-card';
import AdCard from './blocks/ad-card';
import AdElements, { TGPTGeneration } from './blocks/ad-elements';
import Keywords from './blocks/keywords';
import Title from './blocks/table-title';

export type IColumnProp = {
  generateDisabled: boolean;
  onElementGPTGenerate: (type: TGPTGeneration) => Promise<string>;
  onElementGPTFixGrammar: (type: TGPTGeneration) => Promise<string>;
  onElementGPTImprove: (type: TGPTGeneration) => Promise<string>;
  onElementBackAnswer: (value: string, index: number, adsID: number) => string;
  onPreviewClick: (id: string, previewType: string) => void;
  onResetClick: (id: number) => void;
  copyToAll: (id: number) => void;
  handleVariableSelect: (
    variable: string,
    index: number,
    adsID: number
  ) => void;
};

export const LANDING_PAGE_EXP_VALUES: any = {
  '4': 'Above average',
  '3': 'Average',
  '2': 'Below average',
  '1': 'Unknown',
  '0': 'Unspecified',
};

export const TABLE_STEP_THREE_COLUMNS = ({
  generateDisabled,
  onElementGPTGenerate,
  onPreviewClick,
  onResetClick,
  handleVariableSelect,
  onElementGPTFixGrammar,
  onElementGPTImprove,
  onElementBackAnswer,
  copyToAll,
}: IColumnProp) => [
  {
    key: 'ad_status',
    label: () => <div className="m-3 mx-2 w-3 h-3 rounded-full bg-green-400" />,
    width: '0.1%',
    className: () => 'px-2',
    render: (rowItems: any) => (
      <div className="py-2">{AdStatus(rowItems.status)}</div>
    ),
  },
  {
    key: 'ad',
    label: () => <Title className="px-4" title="ad" />,
    width: '34%',
    className: () => 'px-4',
    render: (rowItems: any) => <AdCard {...rowItems} />,
  },
  {
    key: 'keywords',
    label: () => (
      <Title className="px-2.5" title="Clicks / Match type / Keywords" />
    ),
    width: '21%',
    className: () => 'px-2.5',
    render: (rowItems: any) => (
      <Keywords maxToShow={4} keywordTitle={rowItems.keywords} />
    ),
  },
  {
    key: 'ad data',
    label: () => <Title className="px-4" title="ad data" />,
    width: '11%',
    className: () => 'px-4',
    render: (rowItems: any) => (
      <React.Fragment>
        <div>
          <p className="mb-2.5 text-[11px] font-normal leading-none text-gray-800">
            Impr: {rowItems.adEvents.impressions}
          </p>
          <p className="mb-2.5 text-[11px] font-normal leading-none text-gray-800">
            CTR: {rowItems.adEvents.ctr ? rowItems.adEvents.ctr : 0}%
          </p>
          <p className="mb-2.5 text-[11px] font-normal leading-none text-gray-800">
            Clicks: {rowItems.adEvents.clicks}
          </p>
          <p className="mb-2.5 text-[11px] font-normal leading-none text-gray-800">
            QS: {rowItems.adEvents.mid_quality_score || '-'}
          </p>
          <p className="mb-2.5 text-[11px] font-normal leading-none text-gray-800">
            LPE:{' '}
            {LANDING_PAGE_EXP_VALUES[rowItems.adEvents.landing_page_exp] || '-'}
          </p>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: 'personalization elements',
    label: () => <Title className="px-4" title="personalization elements" />,
    width: '25%',
    className: () => 'px-4',
    render: (rowItems: any) => {
      return (
        <AdElements
          adsID={rowItems.id}
          elements={rowItems.elements as IElement[]}
          variables={rowItems.variables}
          onGenerateWithGPT={onElementGPTGenerate}
          onFixGrammarGPT={onElementGPTFixGrammar}
          onImproveWithGPT={onElementGPTImprove}
          onBackAnswer={onElementBackAnswer}
          onHandleSelectVariable={handleVariableSelect}
          disabled={generateDisabled}
        />
      );
    },
  },
  {
    key: 'actions',
    label: () => <Title className="px-4" title="actions" />,
    width: '9.9%',
    className: () => 'pl-4',
    render: (rowItems: any) => {
      const haveVariables = rowItems.elements.filter((item: any) => {
        if (item.personalized_value) {
          return (
            item.personalized_value.includes('{') &&
            item.personalized_value.includes('}') &&
            item.personalized_value.includes(', fallback value:')
          );
        }
      });
      return haveVariables.length > 0 ? (
        <React.Fragment>
          <div className="flex">
            <Dropdown
              className="[&]:flex [&>div]:py-0"
              label={
                <div className="border-0 mb-1 bg-inherit text-xs text-[#1C64F2] text-left">
                  Preview
                </div>
              }
            >
              <DropItem
                onClick={() => {
                  onPreviewClick(rowItems.id, 'fallback');
                }}
                icon={<FallbackPreviewIcon className="mr-2" />}
                className="py-2 text-xs"
              >
                Fallback Preview
              </DropItem>
              <DropItem
                onClick={() => {
                  onPreviewClick(rowItems.id, 'company');
                }}
                icon={<CompanyPreviewIcon className="mr-2" />}
                className="py-2 text-xs"
              >
                Company Preview
              </DropItem>
            </Dropdown>
          </div>
          <button
            type="button"
            className="block mb-1 border-0 bg-inherit text-xs text-[#1C64F2] text-left"
            onClick={() => onResetClick(rowItems.id)}
          >
            Reset
          </button>
          <button
            type="button"
            className="block mb-1 border-0 bg-inherit text-xs text-[#1C64F2] text-left"
            onClick={() => copyToAll(rowItems.id)}
          >
            Apply to all
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="flex">
            <div className="flex">
              <button
                type="button"
                className="block mb-1 border-0 bg-inherit text-xs text-[#1C64F2] text-left"
                onClick={() => onPreviewClick(rowItems.id, 'fallback')}
              >
                Preview
              </button>
            </div>
          </div>
          <button
            type="button"
            className="block mb-1 border-0 bg-inherit text-xs text-[#1C64F2] text-left"
            onClick={() => onResetClick(rowItems.id)}
          >
            Reset
          </button>
          <button
            type="button"
            className="block mb-1 border-0 bg-inherit text-xs text-[#1C64F2] text-left"
            onClick={() => copyToAll(rowItems.id)}
          >
            Apply to all
          </button>
        </React.Fragment>
      );
    },
  },
];

export const TABLE_STEP_FOUR_COLUMNS = [
  {
    key: 'item',
    label: () => <Title title="item" />,
    width: '50%',
    render: (rowItems: any) => <p>{rowItems.title}</p>,
  },
  {
    key: 'value',
    label: () => <Title title="value" />,
    width: '50%',
    render: (rowItems: any) => (
      <div>
        {typeof rowItems.value === 'string' ||
        typeof rowItems.value === 'number'
          ? rowItems.value
          : rowItems.value.map((val: string, index: number) => (
              <p
                key={`${val}-${index}`}
                className="inline-block w-max mr-[22px] py-[2px] px-[10px] text-sm font-medium text-[#03543F] bg-[#DEF7EC] rounded-md"
              >
                {val}
              </p>
            ))}
      </div>
    ),
  },
];

export const TRAFFIC_SOURCE = [
  { label: 'All traffic', value: 'All traffic', disable: true },
  { label: 'Organic', value: 'Organic', disable: true },
  { label: 'Google Ads', value: 'Google Ads' },
  { label: 'LinkedIn Ads', value: 'LinkedIn Ads', disable: true },
  { label: 'Bing Ads', value: 'Bing Ads', disable: true },
  { label: 'G2 Ads', value: 'G2 Ads', disable: true },
  { label: 'Capterra Ads', value: 'Capterra Ads', disable: true },
];

export const AI_GENERATION_INFO = [
  {
    id: 'manual',
    title: 'Manual',
    description:
      'Start with empty fields. Customize every element copy according to your vision.',
    icon: <PencilAlt />,
  },
  {
    id: 'first',
    title: 'Align messaging to ads and keywords ',
    description:
      'Generate elements content that align perfectly with your ad messaging and keywords.',
    icon: <HiOutlineCursorClick size={30} />,
  },
  {
    id: 'second',
    title: 'Industry-Related Content',
    description:
      'Personalized content tailored to visitors industries using filmography dynamic tags.',
    icon: <HiOutlineOfficeBuilding size={30} />,
  },
  {
    id: 'third',
    title: 'Matching Headlines to Top Ad Content',
    description:
      "Match headlines and subheading's to the top ad headlines and descriptions",
    icon: <HiOutlineDuplicate size={30} />,
  },
];
