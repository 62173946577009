export const createOverlay = (color: string, name: string) => {
  const overlay = window[0].document.createElement('div');
  overlay.setAttribute('overlay', name);
  overlay.style.position = 'absolute';
  overlay.style.border = `4px solid ${color}`;
  overlay.style.pointerEvents = 'none';
  overlay.style.zIndex = '9999';
  overlay.style.display = 'none';
  window[0].document.body.appendChild(overlay);
  return overlay;
};

export const positionOverlay = (overlay: HTMLElement, element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  overlay.style.top = `${rect.top + window[0].scrollY}px`;
  overlay.style.left = `${rect.left + window[0].scrollX}px`;
  overlay.style.width = `${rect.width}px`;
  overlay.style.height = `${rect.height}px`;
};

export const clickableWindow = (
  text: any,
  top: string,
  left: number,
  name: string
) => {
  const newDiv = window[0].document.createElement('div');
  newDiv.appendChild(text);

  newDiv.setAttribute('marked-button', name);
  newDiv.style.opacity = '0';
  newDiv.style.position = 'absolute';
  newDiv.style.padding = '4px 12px';
  newDiv.style.paddingRight = '23px';
  newDiv.style.backgroundColor = '#FACA15';
  newDiv.style.pointerEvents = 'none';
  newDiv.style.zIndex = '99';
  const topCount = parseFloat(top);
  newDiv.style.top = `${topCount - 4}px`;

  setTimeout(() => {
    newDiv.style.left = left - newDiv.offsetWidth + 'px';
    newDiv.style.opacity = 'initial';
  }, 0);

  return newDiv;
};

export const windowTitle = (text = '') => {
  const newText = window[0].document.createElement('p');

  newText.style.color = '#FFFFFF';
  newText.style.fontSize = '1rem';
  newText.style.fontWeight = '500';
  newText.style.textAlign = 'left';
  newText.style.letterSpacing = '1px';
  newText.style.margin = 'unset';
  newText.style.webkitTextFillColor = '#FFFFFF';
  newText.textContent = text;
  return newText;
};

export const removeOverlay = (name: string) => {
  const overlay: any = window[0].document.querySelectorAll(
    `[overlay="${name}"]`
  );

  if (overlay.length > 0) {
    overlay.forEach((item: HTMLElement) => item.remove());
  }
};
