import { useState } from 'react';
import { HiCheckCircle, HiExclamation } from 'react-icons/hi';
import { Button, ModalBox, Spinner, Table } from '@pipedataai/storybook';

import { ConversionActionItem } from 'src/store/services/conversion-action/types';
import { useAppSelector } from 'src/store/hooks';
import { parseResponse } from 'src/store/services/helpers';
import {
  useDeleteActionMutation,
  useGetActionsQuery,
} from 'src/store/services/conversion-action/endpoints/conversion-action';

import { customToastMessage } from 'src/components/toast';
import { TABLE_ACTIONS_COLUMNS } from '../constants';

interface IActionList {
  handleEdit: (item: ConversionActionItem) => void;
}

const ActionsList = ({ handleEdit }: IActionList) => {
  const { actions } = useAppSelector((state) => state.conversionActionReducer);
  const { isLoading, refetch } = useGetActionsQuery();
  const [deleteAction] = useDeleteActionMutation();
  const [confirmModal, setConfirmModal] = useState<string>();

  const handleCloseConfirmModal = () => {
    setConfirmModal(undefined);
  };

  const handleDelete = async (id: string) => {
    const response = await deleteAction({ id: parseInt(id) });

    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error',
        1000
      );
    }
    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success',
        200
      );
    }
    refetch();
    setConfirmModal(undefined);
  };

  return (
    <>
      {isLoading && (
        <div className="block items-center p-4 px-10 text-center mt-28">
          <Spinner size="h-6 w-6" color="fill-blue-500" />
        </div>
      )}

      {!isLoading && actions.length <= 0 && (
        <div className="block items-center p-4 px-10 text-center mt-28">
          <div className="flex items-center flex-col gap-y-4 select-none relative">
            <img src="/assets/images/empty_conversions.svg" alt="" />
          </div>
          <div className="mt-10 text-gray-700">
            Create your first conversion action by clicking the button in the
            top right corner.
          </div>
        </div>
      )}

      {!isLoading && actions.length > 0 && (
        <div className="px-10 py-4">
          <Table
            type="light"
            data={actions}
            columns={TABLE_ACTIONS_COLUMNS({
              handleEdit,
              handleDelete: setConfirmModal,
            })}
            isLoading={isLoading}
          />
        </div>
      )}

      <ModalBox
        isOpen={!!confirmModal}
        onClose={handleCloseConfirmModal}
        windowClassName="max-w-md"
      >
        <ModalBox.Body>
          <p className="mb-4 text-base font-bold text-[#111928]">
            Are you sure you want to delete this action?{' '}
          </p>
          <div className="flex justify-end">
            <Button
              baseType="danger"
              baseTitle="Delete"
              className="mr-4"
              onClick={() => handleDelete(confirmModal || '')}
            />
            <Button
              baseType="gray"
              baseTitle="Cancel"
              className="[&]:bg-gray-200"
              onClick={handleCloseConfirmModal}
            />
          </div>
        </ModalBox.Body>
      </ModalBox>
    </>
  );
};

export default ActionsList;
