import { useNavigate } from 'react-router-dom';
import { BreadCrumbs, Button } from '@pipedataai/storybook';

import { AddCross } from '@pipedataai/storybook/dist/components/icons/icons';
import Segments from 'src/features/segments/segments';
import useClearDataNavigate from 'src/helpers/useClearDataNavigate';

export const SegmentsPage = () => {
  const navigate = useNavigate();
  const { handleNavigate } = useClearDataNavigate();

  const handleCreateSegment = () => {
    navigate('/segments/create-segment');
  };

  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <div className="mb-4">
        <BreadCrumbs
          items={[{ title: 'Segments', link: '/segments' }]}
          onNavigateClick={handleNavigate}
        />
      </div>

      <div className="flex items-center justify-between mb-[42px]">
        <h1 className="text-2xl font-semibold">Segments</h1>
        <Button
          baseType="default"
          baseTitle="New Segment"
          onClick={() => handleCreateSegment()}
          Icon={<AddCross />}
        />
      </div>
      <Segments />
    </section>
  );
};
