import React, { useCallback } from 'react';
import { ClearCross } from '@pipedataai/storybook/dist/components/icons/icons';
import OutsideClickHandler from '@pipedataai/storybook/dist/hooks/useOutsideClick';

export type TItem = {
  item: string;
  value: string;
  parentValue?: string;
};

interface ITagInputProps {
  title?: string;
  selectedElements: TItem[];
  onDeleteElement: (value: string) => void;
  ItemList: (handleClose: (e: any) => void) => JSX.Element;
  placeholder?: string;
}

const TagInput = ({
  title,
  selectedElements: selectedElements,
  onDeleteElement,
  ItemList,
  placeholder,
}: ITagInputProps) => {
  const [selectOpen, setSelectOpen] = React.useState(false);

  const handleDelete = (e: any, value: string) => {
    e.stopPropagation();
    onDeleteElement(value);
    setSelectOpen(false);
  };

  const handleClose = useCallback((e: any) => {
    e.stopPropagation();
    setSelectOpen(false);
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={() => setSelectOpen(false)}>
      <div className="relative w-full">
        {title && (
          <p className="mb-3 text-sm font-medium text-gray-900">{title}</p>
        )}
        <div
          className="flex flex-wrap py-2.5 px-3 border border-gray-300 rounded-lg bg-white h-max gap-2.5"
          onClick={() => setSelectOpen(!selectOpen)}
        >
          {selectedElements.length <= 0 && (
            <div className="h-5">
              <p className="text-sm text-gray-400">{placeholder}</p>
            </div>
          )}
          {selectedElements.map((elementName: TItem, index: number) => (
            <span
              key={`${elementName}-${index}`}
              className="flex items-center px-1.5 py-0.5 max-w-max bg-gray-100 text-xs font-medium text-gray-600 rounded"
            >
              {elementName.item}
              <button onClick={(e) => handleDelete(e, elementName.item)}>
                <ClearCross className="ml-1 w-2.5 h-2.5" />
              </button>
            </span>
          ))}
        </div>
        {selectOpen && (
          <div className="absolute inset-x-0 max-h-80 rounded-lg overflow-hidden z-10 shadow-lg bg-white">
            <div className="max-h-80 px-4 pb-4 overflow-y-auto">
              {ItemList(handleClose)}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default TagInput;
