import { useMemo, useState, useEffect } from 'react';

import { useAppSelector } from 'src/store/hooks';
import {
  EAdStatus,
  GoogleAdsData,
} from 'src/store/services/create-campaign/types';
import { useGetClearbitVariablesQuery } from 'src/store/services/variables/endpoints/variables';

import getAllKeys from './getAllKeys';

const useStepDataTransform = (is_detail?: boolean) => {
  const { googleAds } = useAppSelector((state) => state.createCampaignReducer);

  const { data: clearbitVariables } = useGetClearbitVariablesQuery();
  const [vars, setVars] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [step, setStep] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [paginatorLoading, setPaginatorLoading] = useState(false);

  useEffect(() => {
    setVars(getAllKeys(clearbitVariables?.data));
  }, [clearbitVariables?.data]);

  const handlePageChange = (newPage: number) => {
    setPaginatorLoading(true);
    setCurrentPage(newPage);
    setTimeout(() => setPaginatorLoading(false), 200);
  };

  const handleStepChange = (newStep: number) => {
    setStep(newStep);
    setCurrentPage(1);
  };

  const handleSearch = (query: string) => {
    setSearchValue(query);
    setCurrentPage(1);
  };

  const startIndex = currentPage - 1;
  const endIndex =
    +startIndex + +step > googleAds.length
      ? googleAds.length
      : +startIndex + +step;

  const filteredAds = useMemo(() => {
    const filtered = googleAds.filter((adsItem) => {
      const lowerQuery = searchValue.toLowerCase();
      const joinName = adsItem.name.map((item) => item.title).join(' ');
      const lowerTitle = joinName.toLowerCase();
      const lowerGroupName = adsItem.group_name
        ? adsItem.group.name.toLocaleLowerCase()
        : '';

      return (
        adsItem.id.includes(searchValue) ||
        lowerTitle.includes(lowerQuery) ||
        lowerGroupName.includes(lowerQuery)
      );
    });

    const ads = (
      is_detail ? filtered : filtered.slice(startIndex, endIndex)
    ).map((ad: GoogleAdsData) => ({
      id: ad.id,
      status: ad.status,
      ad_group: ad.group.name,
      top_attributes: {
        headlines: ad.name.slice(0, 3),
        descriptions: ad.description.slice(0, 3),
      },
      variables: [
        {
          name: 'Google ads attributes',
          sectionItems: [
            {
              label: ' top headline ',
              value: 'top headline',
            },
            {
              label: 'top description',
              value: 'top description',
            },
          ],
        },
        {
          name: 'Filmography data',
          sectionItems: vars.map((v) => ({
            label: v,
            value: v,
            hasFallbackValue: true,
          })),
        },
      ],
      title: ad.name.map((item) => item.title).join(' | '),
      url: ad.url,
      description: ad.description.map((item) => item.title).join(' | '),
      keywords: ad.keywordsObjects?.slice().sort((a, b) => {
        if (a.clicks === null && b.clicks === null) return 0;
        if (a.clicks === null) return 1;
        if (b.clicks === null) return -1;
        return b.clicks - a.clicks;
      }),
      adEvents: {
        impressions: ad.impressions,
        ctr: (ad.ctr * 100).toFixed(2),
        clicks: ad.clicks,
        mid_quality_score: ad.group.mid_quality_score,
        landing_page_exp: ad.group.landing_page_exp,
      },
      elements: ad.elements,
    }));
    const totalAds = filtered.length;
    const totalKeywords = ads.reduce(
      (total, ad) => total + (ad.keywords?.length || 0),
      0
    );
    return { ads, totalAds, totalKeywords };
  }, [googleAds, is_detail, startIndex, endIndex, searchValue, vars]);

  return {
    currentPage,
    step,
    searchValue,
    googleAdsData: filteredAds.ads.filter(
      (ad) => ad.status === EAdStatus.ENABLED
    ),
    adsCount: filteredAds.totalAds,
    totalKeywordsCount: filteredAds.totalKeywords,
    handlePageChange,
    handleStepChange,
    handleSearch,
    totalCountAds: googleAds.length,
    paginatorLoading,
  };
};

export default useStepDataTransform;
