import { useEffect, useState } from 'react';
import { Spinner } from '@pipedataai/storybook';
import {
  DarkHalfLogo,
  LightHalfLogo,
} from '@pipedataai/storybook/dist/components/icons/images';

import { useAppSelector } from 'src/store/hooks';

const GlobalLoader = () => {
  const [visibleTextIndex, setVisibleTextIndex] = useState(0);
  const { globalLoading, aiLoader } = useAppSelector(
    (state) => state.storageReducer
  );

  const texts = [
    'Loading your preferences...',
    'Fetching your company profile...',
    "Configuring your brand's tone of voice and style...",
    'Generating personalized content...',
  ];

  useEffect(() => {
    if (aiLoader) {
      const intervalId = setInterval(() => {
        setVisibleTextIndex((prevIndex) => {
          if (prevIndex < texts.length - 1) {
            return prevIndex + 1;
          } else {
            clearInterval(intervalId);
            return prevIndex;
          }
        });
      }, 4000);

      return () => {
        clearInterval(intervalId);
        setVisibleTextIndex(0);
      };
    }
  }, [aiLoader, texts.length]);

  return globalLoading ? (
    <div className="fixed top-0 bottom-0 left-0 right-0 items-center justify-center h-screen z-[51]">
      <div className="relative w-1/2 flex item-center justify-center m-auto top-1/2 -translate-y-1/2 z-[52]">
        {aiLoader ? (
          <div className="relative origin-center text-center">
            <Spinner size="h-10 w-10" color="fill-blue-500" />
            {texts.map((text, index) => (
              <p
                key={index}
                className={`${
                  index === 0 ? 'mt-6' : index === 1 ? 'mt-4' : ''
                } leading-tight text-2xl font-semibold text-white transition-opacity duration-500 ${
                  index <= visibleTextIndex ? 'opacity-100' : 'opacity-0'
                }`}
              >
                {text}
              </p>
            ))}
          </div>
        ) : (
          <div className="relative origin-center w-[72px] h-[80px] animate-spin-fast">
            <div className="w-16 h-16 rotate-180">
              <LightHalfLogo />
            </div>
            <div className="w-16 h-16 absolute bottom-0 right-0 rotate-180">
              <DarkHalfLogo />
            </div>
          </div>
        )}
      </div>
      <div
        className={`absolute top-0 left-0 bottom-0 right-0 bg-black ${
          aiLoader ? 'opacity-80' : 'opacity-50'
        }`}
      />
    </div>
  ) : null;
};
// animate-delay-out
export default GlobalLoader;
