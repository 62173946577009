import { useEffect, useMemo, useState } from 'react';

import { SelectOption } from '@pipedataai/storybook/';

import { parseResponse } from 'src/store/services/helpers';
import { useGetCampaignConversionsQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { CampaignConversion } from 'src/store/services/create-campaign/types';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setFilters } from 'src/store/slices/campaignSlice';

interface IGetVersions {
  campaignID: string;
  onChangeVersion: (conversionID: string) => void;
  allPages?: boolean;
}

const useGetCampaignConversions = ({
  campaignID,
  onChangeVersion,
  allPages,
}: IGetVersions) => {
  const dispatch = useAppDispatch();
  const { data, error } = useGetCampaignConversionsQuery(campaignID, {
    skip: allPages,
  });
  const { result, errorMessage } = parseResponse({ data, error });

  const { filters } = useAppSelector((state) => state.createCampaignReducer);

  const [selectedConversion, setSelectedConversion] = useState<
    SelectOption | undefined
  >(undefined);
  const [conversionError, setConversionError] = useState('');

  const conversions = useMemo(() => {
    const versionArr = [];
    versionArr.push({ label: 'All', value: '' });

    if (result) {
      result.forEach((item: CampaignConversion) => {
        versionArr.push({
          label: item.name,
          value: item.id,
        });
      });
    }

    return versionArr;
  }, [result]);

  const handleSelectConversion = (versionID: string) => {
    const currentVersion = conversions.find((ver) => ver.value === versionID);

    if (versionID) {
      if (currentVersion) {
        setSelectedConversion(currentVersion);
        dispatch(
          setFilters({
            ...filters,
            conversions: currentVersion.value,
          })
        );
        onChangeVersion(currentVersion.value);
      }
    } else {
      setSelectedConversion(undefined);
      onChangeVersion('');
      dispatch(setFilters({ ...filters, conversions: '' }));
    }
  };

  useEffect(() => {
    if (error && errorMessage) {
      setConversionError(errorMessage);
    }
    if (result) {
      if (filters.conversions && filters.conversions?.length > 0) {
        const selected = result.find((item) => filters.conversions === item.id);
        if (selected) {
          setSelectedConversion({ label: selected?.name, value: selected?.id });
        }
      }
    }
  }, [result, error, errorMessage, filters.conversions]);

  return {
    conversions,
    selectedConversion,
    error: conversionError,
    handleSelectConversion,
  };
};

export default useGetCampaignConversions;
