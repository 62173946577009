import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from '@pipedataai/storybook/';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { parseError } from 'src/store/services/helpers';
import { useGetAllSegmentsQuery } from 'src/store/services/segments/endpoints/segments';
import { setSegmentID } from 'src/store/slices/campaignSlice';
import useSelectTransformData from '@pipedataai/storybook/dist/hooks/useSelectTransformData';

const useSelectSegment = () => {
  const dispatch = useAppDispatch();
  const { segmentID } = useAppSelector((state) => state.createCampaignReducer);
  const { isLoading, data, error } = useGetAllSegmentsQuery();
  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>(
    undefined
  );

  const result = useMemo(() => data?.data || [], [data]);

  const selectedSegment = useMemo(
    () => result.find((item) => item.segments_id === segmentID),
    [result, segmentID]
  );

  const segments = useSelectTransformData(
    result,
    'segments_name',
    'segments_id'
  );

  const handleSelectedSegment = (selectedID: string) => {
    const selectedAccount = segments?.find((item) => item.value === selectedID);
    if (selectedAccount) {
      setSelectedValue(selectedAccount);
      dispatch(setSegmentID(selectedAccount.value));
    } else {
      setSelectedValue(undefined);
      dispatch(setSegmentID(''));
    }
  };

  useEffect(() => {
    if (selectedSegment && !selectedValue) {
      setSelectedValue({
        label: selectedSegment?.segments_name,
        value: selectedSegment?.segments_id,
      });
      dispatch(setSegmentID(selectedSegment.segments_id));
    }
  }, [dispatch, selectedSegment, selectedValue]);

  if (error) {
    const { errorMessage } = parseError(error);
    return {
      segments: [{ label: '', value: '' }],
      activeSegment: { label: '', value: '' },
      segmentsLoading: isLoading,
      handleSelectedSegment: () => null,
      SegmentsError: errorMessage,
    };
  }

  return {
    segments,
    activeSegment: selectedValue,
    segmentsLoading: isLoading,
    handleSelectedSegment,
    SegmentsError: '',
  };
};

export default useSelectSegment;
