import { format } from 'date-fns';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Dropdown, DropItem } from '@pipedataai/storybook';
import { Edit, Trash } from '@pipedataai/storybook/dist/components/icons/icons';

import TableHeadTitle from '../campaigns/blocks/table-head-title';
import { secToDate } from '../campaigns/constants';

export type TSegment = {
  id: string;
  segmentName: string;
  visitors: number;
  cr: number;
  leads: number;
  avg: number;
  bounce: string;
  campaignsCount: number;
};

export type IColumnProp = {
  handleEditSegment: (segmentID: string) => void;
  setConfirmModal: (segmentID: number) => void;
};

export const TABLE_DASHBOARD_COLUMNS = ({
  handleEditSegment,
  setConfirmModal,
}: IColumnProp) => [
  {
    key: 'segment_title',
    label: () => <TableHeadTitle title="Segment" />,
    width: '25%',
    render: (rowItems: any) => <div>{rowItems.segmentName}</div>,
  },
  {
    key: 'visitors',
    label: () => <TableHeadTitle title="Visitors" textPosition="right" />,
    width: '8%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {Number(rowItems.visitors) || '-'}
      </p>
    ),
  },
  {
    key: 'cr',
    label: () => <TableHeadTitle title="Cr" textPosition="right" />,
    width: '8%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.cr ? rowItems.cr + '%' : '-'}
      </p>
    ),
  },
  {
    key: 'leads',
    label: () => <TableHeadTitle title="Leads" textPosition="right" />,
    width: '8%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {Number(rowItems.leads) || '-'}
      </p>
    ),
  },
  {
    key: 'avg_session',
    label: () => <TableHeadTitle title="Avg Session" textPosition="right" />,
    width: '10%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.avg ? secToDate(rowItems.avg) : 0}
      </p>
    ),
  },
  {
    key: 'bounce',
    label: () => <TableHeadTitle title="Bounce" textPosition="right" />,
    width: '6%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.bounce}
      </p>
    ),
  },
  {
    key: 'campaigns_count',
    label: () => <TableHeadTitle title="Campaigns" textPosition="right" />,
    width: '6%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.campaignsCount}
      </p>
    ),
  },
  {
    key: 'last_edited',
    label: () => <TableHeadTitle title="Last Edited" />,
    width: '14%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-center">
        {rowItems.lastEdited
          ? format(Date.parse(rowItems.lastEdited), 'MM/dd/yy')
          : '-'}
      </p>
    ),
  },
  {
    key: 'dot_popup',
    label: '',
    width: '5%',
    render: (rowItems: any) => (
      <div className="flex">
        <Dropdown
          className="[&>div]:py-0"
          label={
            <div className="flex items-center justify-center bg-gray-100 w-[22px] h-[22px] rounded">
              <HiDotsHorizontal />
            </div>
          }
        >
          <DropItem
            icon={<Edit className="mr-2" />}
            onClick={() => handleEditSegment(rowItems.id)}
            className="py-2 text-xs"
          >
            <span className="text-sm font-normal text-gray-700">Edit</span>
          </DropItem>
          <DropItem
            icon={<Trash className="mr-3" color="#E02424" />}
            onClick={() => setConfirmModal(rowItems.id)}
            className="py-2 text-xs"
          >
            <span className="text-sm font-normal text-red-600">Delete</span>
          </DropItem>
        </Dropdown>
      </div>
    ),
  },
];

export const TAG_VALUES = {
  'Company Tags': false,
  'Account Based Marketing': false,
  'Tech Tags': false,
  'Employee Count Range': true,
  'Annual Revenue Range': true,
  'Industry Attributes': false,
  Countries: false,
  'US & Canadian States and Provin': false,
  'Traffic Rank': true,
  'Company Type': true,
  Seniority: true,
  'Time Zones': false,
  'Business Classifications': true,
};
