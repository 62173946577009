import { googlePageAPI } from '..';
import { ResponseWrap } from '../../response_wrap';
import {
  CheckGoogleSync,
  FindGoogleToken,
  GoogleAdsParams,
  GoogleAnalyticsConfig,
  GoogleCustomers,
  GoogleSignIn,
  SyncGoogleAccount,
} from '../types';

export const googleAPI = googlePageAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSignUrlGoogleAuth: builder.query<ResponseWrap<GoogleSignIn>, void>({
      query: () => ({
        url: 'api/integrations/auth/google',
      }),
    }),
    getSyncCustomers: builder.query<any, void>({
      query: () => ({
        url: 'api/integrations/customers/sync',
      }),
    }),
    getLoadCustomers: builder.query<
      ResponseWrap<GoogleCustomers[]>,
      { searchParam?: string }
    >({
      query: ({ searchParam }) => ({
        url: `api/integrations/customers/load${
          searchParam ? '?q=' + searchParam : ''
        }`,
      }),
    }),
    getSyncMail: builder.query<{ email: string }, void>({
      query: () => ({
        url: 'api/integrations/load/customer/email',
      }),
      providesTags: ['getSyncMail'],
    }),
    syncGoogleAccount: builder.query<CheckGoogleSync, void>({
      query: () => ({
        url: 'api/job-manager/google/all',
      }),
    }),
    syncGoogleAds: builder.query<CheckGoogleSync, void>({
      query: () => ({
        url: 'api/job-manager/google/ads',
      }),
    }),
    checkIsSyncedGoogle: builder.query<CheckGoogleSync, void>({
      query: () => ({
        url: 'api/job-manager/google/status/check',
      }),
      providesTags: ['checkIsSyncedGoogle'],
    }),
    getGAConfig: builder.query<ResponseWrap<GoogleAnalyticsConfig>, void>({
      query: () => ({
        url: 'api/integrations/ga/config',
      }),
    }),
    verifyAdsUrl: builder.query<GoogleAdsParams[], void>({
      query: () => ({
        url: 'api/integrations/google-ads/url-verify',
      }),
    }),
    getGoogleSignInValidate: builder.query<boolean, void>({
      query: () => ({
        url: 'api/integrations/auth/check',
      }),
    }),
    setSyncCustomers: builder.mutation<any, GoogleCustomers[]>({
      query: (body) => ({
        url: 'api/integrations/customers/set_to_sync',
        method: 'POST',
        body: body,
      }),
    }),
    detachGoogle: builder.mutation<void, void>({
      query: () => ({
        url: 'api/integrations/google/detach',
        method: 'POST',
      }),
      invalidatesTags: ['checkIsSyncedGoogle', 'getSyncMail'],
    }),
    storeGoogleTokens: builder.mutation<
      ResponseWrap<CheckGoogleSync>,
      FindGoogleToken
    >({
      query: (body) => ({
        url: 'api/integrations/auth/google/code',
        method: 'POST',
        body: body,
      }),
    }),
    storeGoogleAnalyticsConfig: builder.mutation<
      SyncGoogleAccount,
      GoogleAnalyticsConfig
    >({
      query: (body) => ({
        url: 'api/integrations/ga/config',
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useGetSyncCustomersQuery,
  useCheckIsSyncedGoogleQuery,
  useGetLoadCustomersQuery,
  useGetGAConfigQuery,
  useGetGoogleSignInValidateQuery,
  useGetSyncMailQuery,

  useLazyGetSignUrlGoogleAuthQuery,
  useLazyCheckIsSyncedGoogleQuery,
  useLazyGetLoadCustomersQuery,
  useLazySyncGoogleAccountQuery,
  useLazySyncGoogleAdsQuery,
  useLazyGetSyncCustomersQuery,
  useLazyVerifyAdsUrlQuery,
  useLazyGetGoogleSignInValidateQuery,
  useLazyGetSyncMailQuery,

  useStoreGoogleTokensMutation,
  useStoreGoogleAnalyticsConfigMutation,
  useSetSyncCustomersMutation,
  useDetachGoogleMutation,
} = googleAPI;
