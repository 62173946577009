import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import App from './App';
import { store } from './store';
import Theme from './globals/theme';
import GlobalLoader from './features/loader';
import SyncModal from './features/sync.modal';

import './globals/index.css';
import 'tailwindcss/tailwind.css';
import '@pipedataai/storybook/dist/index';

const DEV_MODE = process.env.NODE_ENV === 'development';
const USETIFUL = process.env.REACT_APP_USETIFUL_TOKEN;

const usetifulTags = document.createElement('script');
usetifulTags.innerHTML = `window.usetifulTags = { segment: "EXAMPLE_SEGMENT_NAME", };`;
const usetifulScript = document.createElement('script');
usetifulScript.innerHTML = `(function (w, d, s) {
  var a = d.getElementsByTagName('head')[0];
  var r = d.createElement('script');
  r.async = 1;
  r.src = s;
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = '${USETIFUL}';
  a.appendChild(r);
})
(window, document, "https://www.usetiful.com/dist/usetiful.js");`;

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal-root');
document.body.appendChild(modalRoot);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <Toaster />
    {!DEV_MODE && (
      <Helmet>
        <script type="text/javascript">
          {`
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js", "ym");
         
            ym(95704900, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
            });
        `}
        </script>
      </Helmet>
    )}
    <Theme />
    <GlobalLoader />
    <SyncModal />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

if (!DEV_MODE) document.body.appendChild(usetifulTags);
if (!DEV_MODE) document.body.appendChild(usetifulScript);
