import { useState } from 'react';
import {
  Button,
  ModalBox,
  SimpleSelect,
  TSelectItem,
} from '@pipedataai/storybook';
import { NextArrow } from '@pipedataai/storybook/dist/components/icons/icons';

import { useGetClearbitCompaniesQuery } from 'src/store/services/variables/endpoints/variables';
import useSelectTransformData from '@pipedataai/storybook/dist/hooks/useSelectTransformData';

interface IModalProps {
  segmentID?: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
}

const CompanyPreviewModal = ({
  isOpen,
  onClose,
  onSave,
  segmentID,
}: IModalProps) => {
  const { data: clearbitVariables } = useGetClearbitCompaniesQuery(segmentID);

  const items = useSelectTransformData(
    clearbitVariables?.data || [],
    'name',
    'domain'
  );

  const [selected, setSelected] = useState<TSelectItem>();

  const handleChange = (value: string) => {
    const selectedValue = items.find((item) => item.value === value);
    if (selectedValue) {
      setSelected(selectedValue);
    }
  };

  const handleSave = () => {
    if (selected) {
      onSave(selected.value);
    }
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} windowClassName="max-w-md">
      <ModalBox.Body className="p-7 pb-0">
        <p className="mb-4 text-2xl font-bold text-gray-900">
          Generate Preview for a Company
        </p>
        <SimpleSelect
          title=""
          value={
            selected?.label || items.length <= 0
              ? "Can't find company for your segment"
              : 'Select company'
          }
          items={items}
          onSelect={handleChange}
          disabled={items.length <= 0}
        />
      </ModalBox.Body>
      <ModalBox.Footer className="border-t-0 p-8 pt-4">
        <div className="flex justify-center">
          <Button baseTitle="Cancel" baseType="outline" onClick={onClose} />
          <Button
            baseTitle="Generate Preview"
            baseType="default"
            onClick={handleSave}
            Icon={<NextArrow className={`'-rotate-90'} ml-2`} />}
            reverse
          />
        </div>
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default CompanyPreviewModal;
