import React, { useEffect, useState } from 'react';
import {
  HiCollection,
  HiUserGroup,
  HiFlag,
  HiOfficeBuilding,
  HiSwitchHorizontal,
  HiCode,
  HiSupport,
} from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'src/store/hooks';
import {
  setClearCampaignData,
  setClearFilters,
} from 'src/store/slices/campaignSlice';
import { resetEditorState } from 'src/store/slices/markup-editor-slice';
import { setClearCampaignsStep } from 'src/store/slices/storageSlice';

import SidebarButton from './sidebar-button';

export interface ISidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: () => void;
}

const Sidebar: React.FC<ISidebarProps> = ({ sidebarOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState('');

  const handlePageNavigate = (page: string, e: any) => {
    e.preventDefault();
    dispatch(setClearCampaignData());
    dispatch(setClearCampaignsStep());
    dispatch(setClearFilters());
    dispatch(resetEditorState());
    setCurrentPage(page);
    navigate(page);
  };

  useEffect(() => {
    const newPage = window.location.pathname;
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  }, [currentPage]);

  return (
    <aside
      className={`bg-white h-full border-r border-gray-200 transition-all duration-300 ${
        sidebarOpen ? 'w-64' : 'w-16'
      }`}
    >
      <nav className="w-full h-full flex flex-col justify-between py-4 px-3">
        <div className="space-y-2">
          {SIDEBAR_URLS.map((item) => (
            <SidebarButton
              key={item.url}
              url={item.url}
              title={item.title}
              Icon={item.icon}
              handlePageNavigate={handlePageNavigate}
              isSidebarOpen={sidebarOpen}
              active={currentPage.includes(item.url)}
            />
          ))}
        </div>
        <div className="space-y-2 mt-4 pt-4 border-t border-gray-200">
          <SidebarButton
            url="/domain"
            title="Client Domains"
            Icon={HiCode}
            handlePageNavigate={handlePageNavigate}
            isSidebarOpen={sidebarOpen}
            active={currentPage.includes('/domain')}
          />
          <SidebarButton
            url="https://link.pipedata.co/help-center"
            title="Help Center"
            Icon={HiSupport}
            isSidebarOpen={sidebarOpen}
            openInNewTab
          />
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;

const SIDEBAR_URLS = [
  {
    url: '/campaigns',
    icon: HiCollection,
    title: 'Campaigns',
  },
  {
    url: '/segments',
    icon: HiUserGroup,
    title: 'Segments',
  },
  {
    url: '/actions',
    icon: HiFlag,
    title: 'Conversion Actions',
  },
  {
    url: '/company-info',
    icon: HiOfficeBuilding,
    title: 'Company Info',
  },
  {
    url: '/integrations',
    icon: HiSwitchHorizontal,
    title: 'Integrations',
  },
];
