import { Button, Dropdown, DropItem } from '@pipedataai/storybook';
import {
  RoundedCheckIcon,
  Trash,
  SaveArrow,
} from '@pipedataai/storybook/dist/components/icons/icons';

import { IElement } from 'src/store/services/create-campaign/types';

interface IHeaderMarkupProp {
  title: string;
  selectedItems: IElement[];
  haveSelectItem: boolean;
  onDeleteItemClick: (item: IElement) => void;
  onSaveClick: (itemsArr: IElement[]) => void;
  onOpenSelectedModal: () => void;
  selectName?: string;
  setElementName?: string;
  isConversionActions?: boolean;
  selectedType?: 'text' | 'button' | 'image' | undefined;
}

type IHeaderProp = IHeaderMarkupProp;

const Header = (props: IHeaderProp) => {
  const {
    selectName,
    isConversionActions,
    setElementName,
    title,
    haveSelectItem,
    onOpenSelectedModal,
    selectedItems,
    onSaveClick,
    onDeleteItemClick,
    selectedType,
  } = props;
  const handleDelete = (e: any, item: IElement) => {
    e.stopPropagation();
    onDeleteItemClick(item);
  };

  return (
    <div className="flex justify-between items-center py-2.5 px-3 border-b-2 border-blue-300 bg-blue-100 small:flex-col">
      <p className="text-xs font-medium text-gray-600 middle:text-ellipsis middle:whitespace-nowrap middle:overflow-hidden middle:w-[calc(100%-640px)] small:w-full small:text-center small:mb-3">
        {title}
      </p>
      <div className="flex items-center">
        {haveSelectItem && (
          <div className="flex items-center mr-6">
            {!isConversionActions && (
              <p className="mr-3.5 text-sm font-medium">
                Element: {selectedType}
              </p>
            )}
            <button
              className="flex items-center px-3 py-2 box-border border-0 rounded-lg bg-green-300 text-xs font-medium hover:bg-green-300"
              onClick={onOpenSelectedModal}
            >
              <RoundedCheckIcon className="mr-2" />
              {setElementName ? setElementName : 'Make replaceable'}
            </button>
          </div>
        )}
        <Dropdown
          className="mr-7 [&>div]:py-0 [&>button]:max-w-40 [&>div]:rounded-lg"
          position="right"
          label={
            <p className="border border-gray-300 rounded-lg bg-white py-2.5 px-3 box-border text-xs text-gray-500 text-left">
              {selectName ? selectName : 'Replaceable Items'} (
              {selectedItems.filter((e) => !e.deletion).length})
            </p>
          }
        >
          {selectedItems
            .filter((e) => !e.deletion)
            .map((item: IElement) => (
              <DropItem
                key={item.path}
                className="flex items-center w-full justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
              >
                <p className="w-full mr-2 text-left">{item.name}</p>
                <div
                  onClick={(e) => handleDelete(e, item)}
                  style={{ cursor: 'pointer' }}
                >
                  <Trash />
                </div>
              </DropItem>
            ))}
        </Dropdown>
        <Button
          baseTitle="Save & Exit"
          Icon={<SaveArrow className="mr-2" />}
          onClick={() => onSaveClick(selectedItems)}
        />
      </div>
    </div>
  );
};

export default Header;
