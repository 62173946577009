import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/store/hooks';
import {
  setClearCampaignData,
  setClearFilters,
} from 'src/store/slices/campaignSlice';
import { setClearCampaignsStep } from 'src/store/slices/storageSlice';
import { resetEditorState } from 'src/store/slices/markup-editor-slice';

const useClearDataNavigate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigate = (href: string) => {
    dispatch(setClearCampaignData());
    dispatch(setClearCampaignsStep());
    dispatch(setClearFilters());
    dispatch(resetEditorState());
    navigate(href);
  };

  return { handleNavigate };
};

export default useClearDataNavigate;
