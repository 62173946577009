import { HiBeaker, HiGlobeAlt, HiDotsHorizontal } from 'react-icons/hi';
import { HiPause, HiPlay } from 'react-icons/hi2';
import { Dropdown, DropItem, CustomTooltip } from '@pipedataai/storybook';
import {
  Edit,
  InfoIcon,
  Trash,
} from '@pipedataai/storybook/dist/components/icons/icons';

import { CampaignItemStatus } from 'src/store/services/create-campaign/types';

import TableHeadTitle from './blocks/table-head-title';
import { CampaignStatusColor } from './blocks/status-line';
import Recommendations from './blocks/recommendation';

export type IColumnProp = {
  handleEditCampaign: (campaignID: number) => void;
  handlePauseCampaign: (campaignID: number) => void;
  handlePublishCampaign: (campaignID: number) => void;
  setConfirmModal: (campaignID: number) => void;
  handleCampaignClick: (campaignID: number, e: any) => void;
};

export const isExperimental = (percentage: number) => {
  return percentage > 0 && percentage < 100;
};

export const itemStatusColor = (campaignStatus: CampaignItemStatus) => {
  switch (campaignStatus) {
    case CampaignItemStatus.Active:
      return 'success';
    case CampaignItemStatus.Draft:
      return 'failure';
    case CampaignItemStatus.Paused:
      return 'gray';
    default:
      '';
  }
  return '';
};

export const secToDate = (sec: number) => {
  const date = new Date(0);
  date.setSeconds(sec); // specify value for SECONDS here
  const dateString = date.toISOString().substring(sec > 3600 ? 11 : 14, 19);
  return dateString == '00:00' ? '-' : dateString;
};

export const TABLE_DASHBOARD_COLUMNS = ({
  handleEditCampaign,
  handlePauseCampaign,
  handlePublishCampaign,
  setConfirmModal,
  handleCampaignClick,
}: IColumnProp) => [
  {
    key: 'personalization_campaign',
    label: () => <TableHeadTitle title="Personalization Campaign" />,
    className: () => 'py-4 px-2.5',
    width: '20%',
    render: (rowItems: any) => (
      <div>
        <button
          className="text-left leading-none"
          onClick={(e) => handleCampaignClick(rowItems.id, e)}
        >
          <a
            className="text-sm text-gray-900 leading-none font-semibold text-left"
            href={`/campaigns/${rowItems.id}`}
          >
            {rowItems.campaignTitle}
          </a>
        </button>
        <p className="mt-1 text-xs font-medium text-gray-600 text-left">
          Google Ads{' '}
          {rowItems.campaignSegments ? `| ${rowItems.campaignSegments}` : ''}
        </p>
      </div>
    ),
  },
  {
    key: 'distribution',
    label: () => <TableHeadTitle title="Distribution" />,
    className: () => 'py-6 px-2.5',
    width: '9%',
    render: (rowItems: any) => (
      <div className="flex">
        <div
          className={`py-1 px-2 w-min rounded-md flex items-center text-xs font-semibold ${
            isExperimental(rowItems.distributionType)
              ? 'text-purple-800 bg-purple-100'
              : 'text-indigo-800 bg-indigo-100'
          }`}
        >
          {isExperimental(rowItems.distributionType) ? (
            <HiBeaker className="mr-1" />
          ) : (
            <HiGlobeAlt className="mr-1" />
          )}
          {isExperimental(rowItems.distributionType) ? 'Experiment' : 'Full'}
        </div>
      </div>
    ),
  },
  {
    key: 'cr_lift',
    label: () => <TableHeadTitle title="CR Lift" textPosition="right" />,
    className: () => 'py-6 pl-2.5 pr-4',
    width: '8%',
    render: (rowItems: any) => (
      <p
        className={`text-sm font-medium ${
          rowItems.crLift.includes('+') ? 'text-green-500' : 'text-gray-900'
        } text-right`}
      >
        {rowItems.crLift}
      </p>
    ),
  },
  {
    key: 'recommendation',
    label: () => (
      <div className="py-4 px-2.5 flex justify-center">
        <p className="text-xs font-semibold text-gray-700">Recs</p>
        <CustomTooltip
          className="ml-1"
          hoverElement={<InfoIcon />}
          position="center"
        >
          <div className="max-w-[192px]">
            <p className="mb-1.5 leading-none text-sm font-medium text-gray-900 text-left normal-case">
              Recommendations
            </p>
            <p className="leading-tight text-xs font-normal text-gray-500 text-left normal-case">
              AI-driven insights based on data and results from past experiments
              and campaigns.{' '}
              <a
                href="https://pipedata.notion.site/RECS-Recommendations-e78ae837c33f4dae870fda06c782ae84"
                className="text-blue-500 underline"
                target="_blank"
              >
                Learn more.
              </a>
            </p>
          </div>
        </CustomTooltip>
      </div>
    ),
    width: '7%',
    className: () => 'py-7 pl-2.5 text-center',
    render: (rowItems: any) => <Recommendations campaignID={rowItems.id} />,
  },
  {
    key: 'visitors',
    label: () => <TableHeadTitle title="Visitors" textPosition="right" />,
    className: () => 'py-6 pl-2.5 pr-4',
    width: '7%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.visitors}
      </p>
    ),
  },
  {
    key: 'cr',
    label: () => <TableHeadTitle title="Cr" textPosition="right" />,
    className: () => 'py-6 pl-2.5 pr-4',
    width: '7%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.cr}%
      </p>
    ),
  },
  {
    key: 'conversions',
    label: () => <TableHeadTitle title="Convs" textPosition="right" />,
    className: () => 'py-6 pl-2.5 pr-4',
    width: '7%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.leads}
      </p>
    ),
  },
  {
    key: 'avg_session',
    label: () => <TableHeadTitle title="Avg Session" textPosition="right" />,
    className: () => 'py-6 pl-2.5 pr-4',
    width: '10%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {secToDate(rowItems.avg)}
      </p>
    ),
  },
  {
    key: 'bounce',
    label: () => <TableHeadTitle title="Bounce" textPosition="right" />,
    className: () => 'py-6 pl-2.5 pr-4',
    width: '8%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-right">
        {rowItems.bounce}
      </p>
    ),
  },
  {
    key: 'created',
    label: () => <TableHeadTitle title="Created" textPosition="center" />,
    width: '8%',
    render: (rowItems: any) => (
      <p className="text-sm font-medium text-gray-900 text-center">
        {rowItems.createdAt}
      </p>
    ),
  },
  {
    key: 'status',
    label: () => <TableHeadTitle title="Status" />,
    width: '7%',
    render: (rowItems: any) => {
      const campaignStatusStyles = CampaignStatusColor(rowItems.status);
      return (
        <div className="flex">
          <div
            className={`py-1 px-2.5 text-xs rounded-md font-semibold ${
              campaignStatusStyles.background +
              ' ' +
              campaignStatusStyles.textColor
            }`}
          >
            {campaignStatusStyles.title}
          </div>
        </div>
      );
    },
  },
  {
    key: 'dot_popup',
    label: '',
    width: '2%',
    render: (rowItems: any) => (
      <div className="flex">
        <Dropdown
          className="[&>div]:py-0"
          label={
            <div className="flex items-center justify-center bg-gray-100 w-[22px] h-[22px] rounded">
              <HiDotsHorizontal />
            </div>
          }
        >
          <DropItem
            className="py-2"
            icon={<Edit className="mr-2" />}
            onClick={() => handleEditCampaign(rowItems.id)}
          >
            <span className="text-sm font-normal text-gray-700">Edit</span>
          </DropItem>
          <DropItem
            className="py-2"
            icon={
              rowItems.status === CampaignItemStatus.Active ? (
                <HiPause className="mr-2" color="#6B7280" />
              ) : (
                <HiPlay className="mr-2" color="#6B7280" />
              )
            }
            onClick={() =>
              rowItems.status === CampaignItemStatus.Active
                ? handlePauseCampaign(rowItems.id)
                : handlePublishCampaign(rowItems.id)
            }
          >
            <span className="text-sm font-normal text-gray-700">
              {rowItems.status === CampaignItemStatus.Active
                ? 'Pause'
                : 'Publish'}
            </span>
          </DropItem>
          <DropItem
            className="py-2"
            icon={<Trash className="mr-3" color="#E02424" />}
            onClick={() => setConfirmModal(rowItems.id)}
          >
            <span className="text-sm font-normal text-red-600">Delete</span>
          </DropItem>
        </Dropdown>
      </div>
    ),
  },
];
