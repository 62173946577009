import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiExclamation } from 'react-icons/hi';
import { Button, Input } from '@pipedataai/storybook';
import { NextArrow } from '@pipedataai/storybook/dist/components/icons/icons';

import {
  useCreateSegmentMutation,
  useLazyGetAllSegmentsQuery,
  useUpdateSegmentMutation,
} from 'src/store/services/segments/endpoints/segments';
import { parseResponse } from 'src/store/services/helpers';
import { customToastMessage } from 'src/components/toast';
import { useLazyGetClearbitCompaniesCountQuery } from 'src/store/services/variables/endpoints/variables';

import useAttributesSorting from './hooks/useAttributesSorting';
import SegmentSection from './blocks/segment-select';
import SelectButton from './blocks/select-button';

type TAddedAttribute = {
  id: string;
  section: string;
};

type TSelectAttribute = {
  section: string;
  ids: string[];
};

interface ISegmentProps {
  segmentID?: string;
  segment?: any;
  setCount?: any;
}

const CreateSegment = ({ segmentID, segment, setCount }: ISegmentProps) => {
  const navigate = useNavigate();
  const [getCompanyCount] = useLazyGetClearbitCompaniesCountQuery();
  const [getAllSegments] = useLazyGetAllSegmentsQuery();
  const { attributes } = useAttributesSorting();
  const [title, setTitle] = React.useState(segment ? segment.name : '');
  const [addedAttributes, setAddedAttributes] = React.useState<
    TAddedAttribute[]
  >(segment ? segment.attributes : []);
  const [savedID, setSavedID] = React.useState<TSelectAttribute[]>([]);

  const [createSegment, { isLoading: createLoad }] = useCreateSegmentMutation();
  const [updateSegment, { isLoading: updateLoad }] = useUpdateSegmentMutation();

  const handleAttributeSelect = async (attributeID: string) => {
    const attributeArr = addedAttributes.slice();
    const attributeName = attributes.find(
      (item) => item.value === attributeID
    )?.label;

    if (!attributeArr.some((item) => item.id === attributeID)) {
      attributeArr.push({
        id: attributeID,
        section: attributeName || '',
      });
      setAddedAttributes(attributeArr);
    }
  };

  const handleRemoveAttribute = (attributeID: string) => {
    const attributeArr = addedAttributes.filter(
      (item) => item.id !== attributeID
    );
    setAddedAttributes(attributeArr);
  };

  const handleAddedSavedIDs = useCallback(
    (values: string[], attributeTitle: string) => {
      setSavedID((prevSavedID) => {
        const existingAttribute = prevSavedID.find(
          (atr) => atr.section === attributeTitle
        );

        if (existingAttribute) {
          return prevSavedID.map((atr) =>
            atr.section === attributeTitle ? { ...atr, ids: values } : atr
          );
        } else {
          return [...prevSavedID, { ids: values, section: attributeTitle }];
        }
      });
    },
    []
  );

  const handleSegmentSave = async () => {
    const savedSegment = {
      name: title,
      attributes: savedID.map((item) => item.ids).flat(),
    };
    let data;
    if (segmentID) {
      data = await updateSegment({ id: segmentID, segment: savedSegment });
    } else {
      data = await createSegment(savedSegment);
    }
    const { result, errorMessage } = parseResponse(data);

    if (errorMessage && !result) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }

    if (result) {
      getAllSegments();
      navigate('/segments');
    }
  };

  useEffect(() => {
    if (segment) {
      setAddedAttributes(segment.attributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment?.attributes.length]);

  useEffect(() => {
    getCompanyCount(savedID).then(({ data }) => {
      setCount(data?.data || 0);
    });
  }, [getCompanyCount, savedID, setCount]);

  return (
    <div className="w-full">
      <Input
        id="segment-name"
        title="Segment title"
        className="mb-7"
        value={title}
        placeholder="New segment"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setTitle(e.target.value)
        }
      />
      {addedAttributes.length > 0 &&
        addedAttributes.map((atr) => (
          <div key={atr.section} className="w-1/2 mb-5">
            <p className="mb-3 text-sm font-medium text-gray-900">
              {atr.section}
            </p>
            <div className="flex">
              <SegmentSection
                segmentID={atr.id}
                firstTitle={atr.section}
                selectedChildren={
                  segment
                    ? segment.attributes.find((item: any) => item.id === atr.id)
                        ?.childAttributes
                    : undefined
                }
                savedSegmentIDs={handleAddedSavedIDs}
                onDeleteAttributeClick={handleRemoveAttribute}
              />
            </div>
          </div>
        ))}
      <SelectButton
        attributesData={[
          {
            attributes: attributes.filter(
              (it) => !addedAttributes.find((item) => item.id === it.value)
            ),
          },
        ]}
        handleAttributeSelect={handleAttributeSelect}
      />
      <Button
        baseType="default"
        baseTitle="Save"
        onClick={handleSegmentSave}
        className="max-w-[81px] mt-12"
        Icon={
          <NextArrow className="ml-2" color={!title ? '#1F2A37' : '#FFFFFF'} />
        }
        disabled={!title}
        isLoading={createLoad || updateLoad}
        reverse={!createLoad || !updateLoad}
      />
    </div>
  );
};

export default CreateSegment;
