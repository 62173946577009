import { HiBeaker, HiGlobeAlt } from 'react-icons/hi';
import { parseISO, format } from 'date-fns';

import { CampaignItemStatus } from 'src/store/services/create-campaign/types';

import { isExperimental } from '../constants';

interface IStatusLineProp {
  status: CampaignItemStatus;
  distribution: number;
  googleCampaignTitle: string;
  createdAt: string;
  campaignURL: string;
}

export const CampaignStatusColor = (campaignStatus: CampaignItemStatus) => {
  switch (campaignStatus) {
    case CampaignItemStatus.Active:
      return {
        color: 'bg-green-400',
        background: 'bg-green-100',
        textColor: 'text-green-900',
        title: 'Active',
      };
    case CampaignItemStatus.Draft:
      return {
        color: 'bg-yellow-400',
        background: 'bg-yellow-100',
        textColor: 'text-yellow-900',
        title: 'Draft',
      };
    case CampaignItemStatus.Paused:
      return {
        color: 'bg-gray-400',
        background: 'bg-gray-100',
        textColor: 'text-gray-900',
        title: 'Paused',
      };
    default:
      return {
        color: 'bg-yellow-400',
        background: 'bg-yellow-100',
        textColor: 'text-yellow-900',
        title: 'Draft',
      };
  }
};

const StatusLine = ({
  status,
  distribution,
  googleCampaignTitle,
  createdAt,
  campaignURL,
}: IStatusLineProp) => {
  const statusStyle = CampaignStatusColor(status);
  const distributionType = isExperimental(distribution);
  return (
    <div className="mb-12">
      <div className="flex items-center ">
        <div className="flex items-center mr-7">
          <div className={`w-2.5 h-2.5 rounded-full ${statusStyle.color}`} />
          <p className="ml-1.5 text-xs font-medium text-gray-500">
            Status: <span className="text-gray-900">{statusStyle.title}</span>
          </p>
        </div>
        <div className="flex items-center mr-7">
          <p className="ml-1.5 text-xs font-medium text-gray-500">
            Distribution:
          </p>
          {distributionType ? (
            <HiBeaker className="ml-1.5 mr-0.5" color="#31C48D" size={12} />
          ) : (
            <HiGlobeAlt className="ml-1.5 mr-0.5" color="#31C48D" size={12} />
          )}
          <span className="text-xs font-medium text-gray-900">
            {distributionType ? 'Experiment' : 'Full'}
          </span>
        </div>
        <div className="flex items-center mr-7">
          <p className="ml-1.5 text-xs font-medium text-gray-500">
            Created:{' '}
            <span className="text-gray-900">
              {format(parseISO(createdAt), 'MMM dd yyyy')}
            </span>
          </p>
        </div>
      </div>
      <div className="flex items-center mt-5">
        <div className="flex items-center mr-7">
          <p className="text-xs font-medium text-gray-500">
            Personalization for Google Ads Campaign:{' '}
            <span className="text-gray-900">{googleCampaignTitle}</span>
          </p>
        </div>
        <div className="flex items-center mr-7">
          <p className="ml-1.5 text-xs font-medium text-gray-500">
            Personalization for URL:{' '}
            <span className="text-gray-900">{campaignURL}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export const StatusLineSkeleton = () => {
  return (
    <div className="mb-12 animate-pulse">
      <div className="flex items-center ">
        <div className="flex items-center mr-7">
          <div className="w-2.5 h-2.5 rounded-full bg-gray-300" />
          <div className="ml-1.5 h-4 bg-gray-300 rounded w-24"></div>
        </div>
        <div className="flex items-center mr-7">
          <div className="ml-1.5 h-4 bg-gray-300 rounded w-20"></div>
          <div className="ml-1.5 h-4 bg-gray-300 rounded w-6"></div>
          <div className="ml-1.5 h-4 bg-gray-300 rounded w-16"></div>
        </div>
        <div className="flex items-center mr-7">
          <div className="ml-1.5 h-4 bg-gray-300 rounded w-32"></div>
        </div>
      </div>
      <div className="flex items-center mt-5">
        <div className="flex items-center mr-7">
          <div className="h-4 bg-gray-300 rounded w-72"></div>
        </div>
        <div className="flex items-center mr-7">
          <div className="ml-1.5 h-4 bg-gray-300 rounded w-72"></div>
        </div>
      </div>
    </div>
  );
};

export default StatusLine;
