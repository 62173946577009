import { useEffect, useState } from 'react';
import { HiExclamation } from 'react-icons/hi';

import { customToastMessage } from 'src/components/toast';

import { useAppSelector } from 'src/store/hooks';
import { parseResponse } from 'src/store/services/helpers';
import {
  useLazyGetSignUrlGoogleAuthQuery,
  useLazyGetSyncCustomersQuery,
  useStoreGoogleTokensMutation,
} from 'src/store/services/google/endpoints/google';
import { SyncStatus } from 'src/store/services/google/types';

import IntegrationCard from './blocks/integration-card';
import GAModal from './blocks/ga-modal';
import GAConfigureModal from './blocks/ga-configure-modal';
import SyncAdsModal from './blocks/sync-modal';

const Integration = () => {
  const [showModal, setShowModal] = useState(false);
  const [syncDisabled, setSyncDisabled] = useState(false);
  const [syncAdsModalOpen, setSyncAdsModalOpen] = useState('');
  const [showModalConfigure, setShowModalConfigure] = useState(false);
  const [syncLoad, setSyncLoad] = useState(false);
  const [gaLoading, setGaLoading] = useState(false);
  const { isSyncing, latestSync, signInGoogle } = useAppSelector(
    (state) => state.googleSyncReducer
  );
  const [storeGoogleTokens, { isLoading }] = useStoreGoogleTokensMutation();

  const [getSignUrlGoogleAuth] = useLazyGetSignUrlGoogleAuthQuery();
  const [getSyncCustomers] = useLazyGetSyncCustomersQuery();

  const handleShowInfo = (helpButton: boolean) => {
    if (!latestSync) {
      customToastMessage(
        "For proper analytics integration, it's necessary to link your Google account.",
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }
    if (helpButton) {
      setShowModal(true);
    } else {
      setShowModalConfigure(true);
    }
  };

  const handleConnect = () => {
    if (signInGoogle) {
      setSyncAdsModalOpen('second');
      return;
    }

    const width = window.innerWidth * 0.6;
    const height = window.innerHeight * 0.6;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const authWindow = window.open(
      '',
      'GoogleAuthWindow',
      `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`
    );

    if (!authWindow) {
      customToastMessage(
        'Failed to open authentication window. Please disable your popup blocker and try again.',
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }

    getSignUrlGoogleAuth().then(({ data, error }) => {
      const { result, errorMessage } = parseResponse({ data, error });

      if (error && errorMessage) {
        customToastMessage(
          errorMessage,
          <HiExclamation className="h-5 w-5" />,
          'error'
        );
        return;
      }

      if (result) {
        authWindow.location.href = result.data.url;

        const checkForCode = setInterval(() => {
          try {
            if (authWindow && !authWindow.closed) {
              if (authWindow.location.hostname === window.location.hostname) {
                authWindow.resizeTo(1, 1);
                authWindow.moveTo(-9999, -9999);
                const urlParams = new URLSearchParams(
                  authWindow.location.search
                );
                const code = urlParams.get('code');
                const error = urlParams.get('error');

                if (error) {
                  customToastMessage(
                    'Authorization cancelled',
                    <HiExclamation className="h-5 w-5" />,
                    'warning'
                  );
                  clearInterval(checkForCode);
                  authWindow.close();
                  return;
                }

                if (code) {
                  setSyncLoad(true);
                  handleReceivedCode(code);
                  setSyncAdsModalOpen('first');
                  clearInterval(checkForCode);
                  authWindow.close();
                }
              }
            } else {
              clearInterval(checkForCode);
            }
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }, 500);
      }
    });
  };

  const handleReceivedCode = async (code: string) => {
    await storeGoogleTokens({ code: code });
    await getSyncCustomers();
    setSyncLoad(false);
  };

  useEffect(() => {
    if (SyncStatus[isSyncing] === SyncStatus[2] || isLoading) {
      setSyncDisabled(true);
    } else {
      setSyncDisabled(false);
    }
  }, [isLoading, isSyncing]);

  return (
    <div className="flex">
      <IntegrationCard
        type="sync"
        image="/assets/images/ic_google.png"
        lazyImage="/assets/images/ic_google_lazy.png"
        onHandleConnect={handleConnect}
        disabled={syncDisabled}
        isSyncing={isSyncing}
        isLoading={isLoading}
        signInGoogle={signInGoogle}
      />
      <IntegrationCard
        type="analytic"
        image="/assets/images/google_analitic.png"
        lazyImage="/assets/images/google_analitic_lazy.png"
        onHandleConnect={() => handleShowInfo(false)}
        disabled={syncDisabled}
        onShowInfo={() => handleShowInfo(true)}
        isLoading={isLoading}
        isLoadingRemoteGAConfig={gaLoading}
      />
      <SyncAdsModal
        showSyncModal={!!syncAdsModalOpen}
        handleHideSyncModal={() => setSyncAdsModalOpen('')}
        syncLoad={syncLoad}
        firstSync={syncAdsModalOpen === 'first'}
      />
      <GAModal
        showModal={showModalConfigure}
        handleHideModal={() => setShowModalConfigure(false)}
        setGaConfigLoading={setGaLoading}
      />
      <GAConfigureModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setShowModalConfigure(true);
        }}
      />
    </div>
  );
};

export default Integration;
