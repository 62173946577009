import { useCallback, useEffect, useMemo, useState } from 'react';
import { IGroupItem, SelectOption } from '@pipedataai/storybook/';

import { parseError } from 'src/store/services/helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setGoogleAdsUrls,
  setSelectedGoogleAdsCampaign,
} from 'src/store/slices/campaignSlice';
import { useGetGoogleAdsCampaignQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';

const GOOGLE_CAMPAIGN_STATUS = [
  'Unspecified',
  'Unknown',
  'Enabled',
  'Paused',
  'Removed',
];

const dynamicSort = (array: IGroupItem[] | undefined) => {
  const groupStatus = [
    'Enabled',
    'Paused',
    'Removed',
    'Unknown',
    'Unspecified',
  ];
  if (array) {
    array.sort((a, b) => {
      const statusA = a.groupName;
      const statusB = b.groupName;
      return groupStatus.indexOf(statusA) - groupStatus.indexOf(statusB);
    });

    array.forEach((groupItem) => {
      groupItem.groupItems.sort((a, b) => {
        const valueA = a.label.toLowerCase();
        const valueB = b.label.toLowerCase();

        return valueA.localeCompare(valueB);
      });
    });

    return array;
  }

  return array;
};

const useSelectCampaignGoogleADS = () => {
  const dispatch = useAppDispatch();
  const { googleAdsAccount, googleAdsCampaing, campaignID } = useAppSelector(
    (state) => state.createCampaignReducer
  );
  const { data, isLoading, error } = useGetGoogleAdsCampaignQuery(
    { customerID: googleAdsAccount },
    {
      skip: googleAdsAccount === '',
    }
  );

  const selectedCampaign = useMemo(
    () => data?.data.find((item) => item.id === googleAdsCampaing),
    [data, googleAdsCampaing]
  );

  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>(
    undefined
  );

  const campaigns: IGroupItem[] = useMemo(() => {
    if (!data) return [];

    const groupedCampaigns: any = {};

    data.data.forEach((account) => {
      const groupName = GOOGLE_CAMPAIGN_STATUS[account.status];

      if (!groupedCampaigns[groupName]) {
        groupedCampaigns[groupName] = {
          groupName,
          groupItems: [],
        };
      }
      groupedCampaigns[groupName].groupItems.push({
        label: account.name,
        value: account.id,
        disable: account.extended_urls
          ? account.extended_urls.filter(
              (urls) =>
                !urls.personalized_experience_id &&
                urls.personalized_experience_id !== campaignID
            ).length === 0
          : [],
      });
    });
    return Object.values(groupedCampaigns);
  }, [campaignID, data]);

  const handleSelectedCampaign = useCallback(
    (selectedID: string) => {
      const selectedAccount = campaigns
        ?.find((item) =>
          item.groupItems.find((value) => value.value === selectedID)
        )
        ?.groupItems.find((item) => item.value === selectedID);

      if (selectedAccount) {
        setSelectedValue(selectedAccount);
        dispatch(setSelectedGoogleAdsCampaign(selectedAccount));
      }
    },
    [campaigns, dispatch]
  );

  useEffect(() => {
    if (selectedCampaign && !selectedValue) {
      setSelectedValue({
        label: selectedCampaign?.name,
        value: selectedCampaign?.id,
        disable: selectedCampaign?.extended_urls
          ? selectedCampaign?.extended_urls.filter(
              (url) =>
                !url.personalized_experience_id &&
                url.personalized_experience_id !== campaignID
            ).length === 0
          : true,
      });
      dispatch(
        setSelectedGoogleAdsCampaign({
          label: selectedCampaign?.name,
          value: selectedCampaign?.id,
          has_company: selectedCampaign?.extended_urls
            ? selectedCampaign?.extended_urls.filter(
                (url) =>
                  !url.personalized_experience_id &&
                  url.personalized_experience_id !== campaignID
              ).length === 0
            : true,
        })
      );
      dispatch(
        setGoogleAdsUrls(
          selectedCampaign.extended_urls
            ? selectedCampaign.extended_urls.map((item) => item.url)
            : []
        )
      );
    }
  }, [campaignID, dispatch, selectedCampaign, selectedValue]);

  if (error) {
    const { errorMessage } = parseError(error);
    return {
      accounts: [{ label: '', value: '' }],
      activeAccount: { label: '', value: '' },
      handleSelectedCampaign: () => null,
      googleCampaignError: errorMessage,
    };
  }

  return {
    campaigns: dynamicSort(campaigns),
    activeCampaign: selectedValue,
    campaignsLoading: isLoading,
    handleSelectedCampaign,
    googleCampaignError: '',
  };
};

export default useSelectCampaignGoogleADS;
