import { useEffect, useState } from 'react';
import { HiCheckCircle, HiExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Button, ModalBox, Table } from '@pipedataai/storybook';

import { CampaignListItem } from 'src/store/services/create-campaign/types';
import { useDeleteCampaignMutation } from 'src/store/services/create-campaign/endpoints/create-campaign';
import { parseResponse } from 'src/store/services/helpers';
import { setAiLoader, setGlobalLoading } from 'src/store/slices/storageSlice';
import { useAppDispatch } from 'src/store/hooks';

import { customToastMessage } from 'src/components/toast';

import { TABLE_DASHBOARD_COLUMNS } from '../constants';
import useDataTransform from '../hooks/useDataTransform';

interface IDashboardTable {
  campaigns: CampaignListItem[] | undefined;
  handleEditCampaign: (campaignID: number) => void;
  handlePauseCampaign: (campaignID: number) => void;
  handlePublishCampaign: (campaignID: number) => void;
  isLoading?: boolean;
}

const DashboardTable = ({
  campaigns,
  handleEditCampaign,
  handlePauseCampaign,
  handlePublishCampaign,
  isLoading,
}: IDashboardTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteCampaign] = useDeleteCampaignMutation();
  const [confirmModal, setConfirmModal] = useState<number>();
  const { campaignsData } = useDataTransform(campaigns);

  const handleCampaignClick = (id: number, e: any) => {
    const ctrlClicked = sessionStorage.getItem('ctrlDown');
    if (ctrlClicked === null || ctrlClicked !== 'true') {
      e.preventDefault();
      navigate(`/campaigns/${id}`);
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(undefined);
  };

  const handleDeleteCampaign = async (id: number) => {
    handleCloseConfirmModal();
    dispatch(setGlobalLoading(true));
    dispatch(setAiLoader(false));
    const response = await deleteCampaign(id);

    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error',
        200
      );
      dispatch(setGlobalLoading(false));
    }

    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
      dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    window.document.onkeydown = (event) => {
      if (event.ctrlKey || event.metaKey) {
        sessionStorage.setItem('ctrlDown', 'true');
      }
    };
    window.document.onkeyup = (event) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        sessionStorage.setItem('ctrlDown', 'false');
      }
    };
  }, []);

  return (
    <div className="py-4">
      <Table
        type="light"
        data={campaignsData}
        columns={TABLE_DASHBOARD_COLUMNS({
          handleEditCampaign,
          handlePauseCampaign,
          handlePublishCampaign,
          setConfirmModal,
          handleCampaignClick,
        })}
        isLoading={isLoading}
      />
      <ModalBox
        isOpen={!!confirmModal}
        onClose={handleCloseConfirmModal}
        windowClassName="max-w-md"
      >
        <ModalBox.Body>
          <p className="mb-4 text-base font-bold text-[#111928]">
            Are you sure you want to delete this campaign?{' '}
          </p>
          <div className="flex justify-end">
            <Button
              baseType="danger"
              className="mr-4"
              baseTitle="Delete"
              onClick={() => handleDeleteCampaign(confirmModal || 0)}
            />
            <Button
              baseType="gray"
              className="[&]:bg-gray-200"
              baseTitle="Cancel"
              onClick={handleCloseConfirmModal}
            />
          </div>
        </ModalBox.Body>
      </ModalBox>
    </div>
  );
};

export default DashboardTable;
