import { useEffect, useRef, useState } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { Table } from '@pipedataai/storybook';

import { TABLE_AD_ELEMENTS } from '../constants';

export type TTABLE_ELEMENT = {
  id: number;
  name: string;
  default_value: string;
  personalized_value: string;
  element_type: string;
  destination_url?: string;
  default_url?: string;
};

interface IAdElement {
  id: string;
  elements: TTABLE_ELEMENT[];
  updated?: string;
}

const AdElements = ({ id, elements, updated }: IAdElement) => {
  const menu = useRef<HTMLButtonElement | null>(null);
  const dropdown = useRef<HTMLDivElement | null>(null);

  const [adInfoOpen, setAdInfoOpen] = useState(false);
  const [arrowPosition, setArrowPosition] = useState({
    y: 0,
    x: 0,
    isTop: false,
  });

  const findElementTop = () => {
    if (menu.current && dropdown.current) {
      const windowHeight = window.innerHeight;
      const menuBounds = menu.current.getBoundingClientRect();
      const dropdownElement = dropdown.current;

      const { height: popupHeight } = dropdownElement.getBoundingClientRect();
      const { top, left, width: menuWidth, height: menuHeight } = menuBounds;
      const popupPosition = left - menuWidth;
      const arrowXPosition = left + menuWidth / 2;

      const freeSpace = windowHeight - top + menuHeight;
      const topPosition = top + 387 >= windowHeight;
      const maxHeight = freeSpace > 387 ? 387 : freeSpace;

      const positionY = Math.floor(
        topPosition
          ? top - (popupHeight > 387 ? 387 : popupHeight) - 12
          : top + menuHeight + 8
      );

      dropdownElement.style.cssText = ` 
        top: ${positionY}px; 
        left: ${popupPosition}px; 
        max-height: ${
          topPosition ? (popupHeight > 387 ? 387 : popupHeight) : maxHeight
        }px;
      `;

      setArrowPosition({
        y: Math.floor(
          topPosition ? positionY - 8 + popupHeight : positionY - 6
        ),
        x: Math.floor(arrowXPosition),
        isTop: topPosition,
      });
    }
  };

  const handleMenuClicked = () => {
    setAdInfoOpen(!adInfoOpen);

    findElementTop();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (adInfoOpen) {
        findElementTop();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [adInfoOpen]);

  return (
    <div className="relative">
      <button ref={menu} onClick={handleMenuClicked}>
        <p className="font-medium text-xs text-blue-600 text-left">
          View elements
        </p>
        {updated && (
          <p className="font-normal text-xs text-gray-500 text-left">
            upd: {Intl.DateTimeFormat('en-US').format(new Date(updated))}
          </p>
        )}
      </button>
      <div
        ref={dropdown}
        className={`${
          adInfoOpen
            ? 'opacity-100 z-[61] pointer-events-auto'
            : 'opacity-0 -z-[1] pointer-events-none'
        } fixed max-w-[720px] border border-gray-200 rounded-md`}
      >
        <div
          className={`fixed w-4 h-4 transform -translate-x-1/2 border border-gray-200 ${
            arrowPosition.isTop
              ? `rotate-45 bg-gradient-to-tl from-white from-50% to-transparent via-50% z-[1] border-t-0 border-l-0`
              : `rotate-45 bg-blue-50 -z-[1]`
          }`}
          style={{
            left: `${arrowPosition.x}px`,
            top: `${arrowPosition.y}px`,
          }}
        />
        <div className="flex justify-between items-center py-2 px-2.5 bg-blue-50 border-b border-gray-200">
          <div className="flex">
            <p className="font-medium text-xs text-gray-500">
              Personalized elements for ad:{' '}
              <span className="text-purple-800">{id}</span>
            </p>
          </div>
          <button onClick={() => setAdInfoOpen(false)}>
            <HiOutlineX color="#6B7280" />
          </button>
        </div>
        <div className="max-h-[343px] bg-white overflow-y-auto">
          <Table type="light" data={elements} columns={TABLE_AD_ELEMENTS} />
        </div>
      </div>
    </div>
  );
};

export default AdElements;
