import { Button, ModalBox } from '@pipedataai/storybook';

interface IGaConfigureProp {
  open: boolean;
  onClose: () => void;
}

const GAConfigureModal = ({ open, onClose }: IGaConfigureProp) => {
  return (
    <ModalBox isOpen={open} windowClassName="max-w-4xl" onClose={onClose}>
      <ModalBox.Header onClose={onClose}>
        How to integrate Google Analytics
      </ModalBox.Header>
      <ModalBox.Body>
        <div className="text-base font-normal p-0">
          The API SECRET generated in the Google Analytics UI.
          <br />
          To create a new secret, navigate to <b>Admin</b> &gt;{' '}
          <b>Data Streams</b> &gt; <b>choose your stream</b> &gt;{' '}
          <b>Measurement Protocol</b> &gt; <b>Create</b>.
          <br />
          <br />
          The measurement ID associated with a stream, found in the Google
          Analytics UI under <b>Admin</b> &gt; <b>Data Streams</b> &gt;{' '}
          <b>choose your stream</b> &gt; <b>Measurement ID</b>. The
          measurement_id isn&apos;t your <b>Stream ID</b>.
        </div>
      </ModalBox.Body>
      <ModalBox.Footer>
        <Button onClick={onClose} baseTitle="Got it" />
      </ModalBox.Footer>
    </ModalBox>
  );
};

export default GAConfigureModal;
