import { Dropdown, DropItem } from '@pipedataai/storybook';
import { AddCross } from '@pipedataai/storybook/dist/components/icons/icons';

export type TAttributeType = 'checkbox' | 'select' | 'range';

export type TItem = {
  label: string;
  value: string;
};

export type TAttribute = {
  section?: string;
  attributes: TItem[];
};

interface IAddAttributeProps {
  attributesData: TAttribute[];
  handleAttributeSelect: (id: string) => void;
}

const SelectButton = ({
  attributesData,
  handleAttributeSelect,
}: IAddAttributeProps) => {
  return (
    <div className="flex">
      <Dropdown
        label={
          <div className="flex items-center max-w-[126px] px-1.5 py-2 border border-primary-700 rounded-lg">
            <AddCross className="mr-1" color="#1A56DB" />
            <span className="text-sm font-medium text-primary-700">
              Add attribute
            </span>
          </div>
        }
        className="[&>div]:py-0 [&>div>div]:overflow-y-auto"
        position="right"
        defaultHeight={175}
      >
        {attributesData.map((attributeSection) => (
          <div key={`fermo-${attributeSection.section}`}>
            {attributeSection.section && (
              <p className="py-2 px-4 text-sm font-bold text-gray-700">
                {attributeSection.section}
              </p>
            )}
            {attributeSection.attributes.map((attribute: TItem) => (
              <DropItem
                className="py-2"
                key={`fetmo-${attributeSection.section}-${attribute.value}`}
                onClick={() => handleAttributeSelect(attribute.value)}
              >
                <p className="text-sm font-normal text-gray-700">
                  {attribute.label}
                </p>
              </DropItem>
            ))}
          </div>
        ))}
      </Dropdown>
    </div>
  );
};

export default SelectButton;
