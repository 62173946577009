import { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import {
  HiOutlineRefresh,
  HiOutlineExclamation,
  HiOutlineCheckCircle,
} from 'react-icons/hi';
import { format } from 'date-fns';

import { SyncStatus, SyncStatusKeys } from 'src/store/services/google/types';

type TButtonStatus = 'error' | 'loading' | 'success';

interface ISyncButtonProps {
  isSyncing: SyncStatusKeys;
  onSyncClick: () => void;
  error?: string;
  syncDate?: string;
  isLoading?: boolean;
}

const SyncButton = ({
  isSyncing,
  error,
  syncDate,
  isLoading,
  onSyncClick,
}: ISyncButtonProps) => {
  const syncStatus = SyncStatus[isSyncing];

  const tooltipTitle = useMemo(() => {
    return error
      ? error
      : syncStatus === SyncStatus[2] || isLoading
      ? 'Your Google Ads data sync is in progress. It may take some time.'
      : 'Google Ads data has been successfully synchronized.';
  }, [error, isLoading, syncStatus]);

  const buttonStatus = useMemo(() => {
    return error || syncStatus === SyncStatus[4]
      ? 'error'
      : syncStatus === SyncStatus[2] || isLoading
      ? 'loading'
      : 'success';
  }, [error, isLoading, syncStatus]);

  return (
    <div className="flex items-center">
      <p className="text-xs font-medium text-gray-700">
        {error ? null : (
          <span>
            Google Ads last synced:
            {syncStatus === SyncStatus[2] || isLoading ? (
              <span className="ml-1">In progress...</span>
            ) : syncDate ? (
              <span className="ml-1">
                {format(Date.parse(syncDate), 'MMM d, h:mm a')}
              </span>
            ) : null}
          </span>
        )}
      </p>
      <button
        type="button"
        className="flex items-center ml-2 p-1.5 px-2 border rounded-lg text-xs shadow-[0_1px_1px_0_rgb(0,0,0,0.05)] active:shadow-lg active:bg-gray-100"
        onClick={onSyncClick}
        disabled={syncStatus === SyncStatus[2] || isLoading}
        data-tooltip-id="sync-button-tooltip"
        data-tooltip-place="bottom-start"
        data-tooltip-content={tooltipTitle}
      >
        <ButtonContent status={buttonStatus} />
      </button>
      <Tooltip id="sync-button-tooltip" />
    </div>
  );
};

const ButtonContent = ({ status }: { status: TButtonStatus }) => {
  switch (status) {
    case 'error':
      return (
        <div className="flex items-center">
          <HiOutlineExclamation className="text-orange-500" />
          <span className="ml-1 text-orange-600">Sync</span>
        </div>
      );
    case 'loading':
      return (
        <div className="flex items-center">
          <HiOutlineRefresh className="animate-spin-slow text-blue-500" />
          <span className="ml-1 text-blue-600">Sync</span>
        </div>
      );
    default:
      return (
        <div className="flex items-center">
          <HiOutlineCheckCircle className="text-green-500" />
          <span className="ml-1 text-green-600">Sync</span>
        </div>
      );
  }
};

export default SyncButton;
