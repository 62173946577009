import React from 'react';

interface ProfileSectionProps {
  children: React.ReactNode;
  className?: string;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`flex rounded-lg bg-white shadow-sm border-0 w-full overflow-hidden items-center gap-4 px-8 py-8 ${className}`}
    >
      {children}
    </div>
  );
};

export default ProfileSection;
