import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCheckHoursPassed as getCheckHoursPassed } from '@pipedataai/storybook';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setRedirectAfterSync } from 'src/store/slices/storageSlice';

const useSyncCampaign = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { latestSync, isSyncing } = useAppSelector(
    (state) => state.googleSyncReducer
  );

  const passEightHoursPerSync = useMemo(
    () => getCheckHoursPassed(8, latestSync || ''),
    [latestSync]
  );

  const handleRedirectPage = async (url: string) => {
    if (!latestSync) {
      navigate('/integrations');
      return;
    }
    if (!passEightHoursPerSync) {
      navigate(url);
      return;
    } else {
      dispatch(setRedirectAfterSync(url));
    }
  };

  return {
    isSyncing,
    handleRedirectPage,
  };
};

export default useSyncCampaign;
