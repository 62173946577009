import { useEffect } from 'react';
import { HiExclamation } from 'react-icons/hi';
import { BreadCrumbs, Spinner } from '@pipedataai/storybook';

import { useAppSelector } from 'src/store/hooks';
import {
  useGetProfileQuery,
  useGetPromoQuery,
} from 'src/store/services/user/endpoints/user';
import { parseError } from 'src/store/services/helpers';
import { useGetAllUserDomainsQuery } from 'src/store/services/client-domains/endpoints/client-domains';

import { customToastMessage } from 'src/components/toast';
import useClearDataNavigate from 'src/helpers/useClearDataNavigate';

import Profile from 'src/features/profile/profile';

export function AccountPage() {
  useGetPromoQuery();
  const { isLoading, data, error } = useGetProfileQuery();
  const { handleNavigate } = useClearDataNavigate();
  const { domains } = useAppSelector((state) => state.clientDomainReducer);
  useGetAllUserDomainsQuery(undefined, { skip: domains.length > 0 });

  useEffect(() => {
    if (error) {
      const { errorMessage } = parseError(error);
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
  }, [error]);

  return (
    <section>
      <div className="block items-center justify-between py-4 pt-6 px-10">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <BreadCrumbs
              items={[{ title: 'Account', link: '/account' }]}
              onNavigateClick={handleNavigate}
            />
          </div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            Account
          </h1>
        </div>
      </div>

      <div className="block items-center justify-between p-4 px-10">
        {isLoading && <Spinner color="fill-blue-500" />}
        {!isLoading && data?.data != null && <Profile user={data?.data} />}
      </div>
    </section>
  );
}
