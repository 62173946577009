import React, { ChangeEvent } from 'react';
import { HiExclamation } from 'react-icons/hi';
import { Button, Input, Spinner, Textarea } from '@pipedataai/storybook';
import { SaveBlueArrow } from '@pipedataai/storybook/dist/components/icons/icons';

import { useAppSelector } from 'src/store/hooks';
import { useSaveCompanyInfoMutation } from 'src/store/services/company-info/endpoints/company-info';

import { customToastMessage } from 'src/components/toast';

const CompanyInfo = () => {
  const { id, name, site, description, brandVoice } = useAppSelector(
    (state) => state.companyInfoReducer
  );
  const [saveCompanyInfo, { isLoading }] = useSaveCompanyInfoMutation();

  const [title, setTitle] = React.useState(name);
  const [siteUrl, setSiteUrl] = React.useState(site);
  const [companyDesc, setCompanyDesc] = React.useState(description);
  const [locBrandVoice, setLocBrandVoice] = React.useState(brandVoice);

  const handleSave = () => {
    const emptyFields = [];

    if (!title) emptyFields.push('Company name');
    if (!siteUrl) emptyFields.push('Company Website');
    if (!companyDesc) emptyFields.push('Company Description');
    if (!locBrandVoice) emptyFields.push('Company Brand Voice');

    if (emptyFields.length === 0) {
      const companyBodyDto = {
        id: id || '',
        name: title,
        page_url: siteUrl,
        description: companyDesc,
        tone_of_voice: locBrandVoice,
      };

      saveCompanyInfo({ ...companyBodyDto });
    } else {
      const errorMessage = `Please fill the following fields: ${emptyFields.join(
        ', '
      )}`;
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error',
        1000
      );
    }
  };

  return (
    <div className="mt-8 mb-6 p-8 w-full bg-white rounded-lg">
      <p className="text-lg font-semibold text-gray-900">
        Add information about your company
      </p>
      <div className="w-1/2 mt-2">
        <p className="text-sm font-normal text-gray-700">
          Add information about your company, it’s positioning, messaging and
          branding. This information will be used to train our model for
          generating personalized content.{' '}
        </p>
        <div className="mt-6">
          <Input
            id="company-name"
            title="Company name"
            className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none"
            value={title}
            placeholder="Company"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTitle(e.target.value)
            }
          />
        </div>
        <div className="my-4">
          <Input
            id="company-website"
            title="Company Website"
            className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none"
            value={siteUrl}
            placeholder="https://example.com/"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSiteUrl(e.target.value)
            }
          />
        </div>
        <Textarea
          id="description"
          label="Company Description"
          value={companyDesc}
          placeholder="Write text here ..."
          required={false}
          rows={3}
          onChange={(e) => setCompanyDesc(e.target.value)}
        />
        <Textarea
          id="brand-voice"
          label="Company Brand Voice"
          className="my-4"
          value={locBrandVoice}
          placeholder="Write text here ..."
          required={false}
          rows={3}
          onChange={(e) => setLocBrandVoice(e.target.value)}
        />
        <Button
          baseType="default"
          baseTitle="Save"
          onClick={handleSave}
          Icon={
            isLoading ? (
              <Spinner
                className="flex mr-2.5"
                size="h-3 w-3"
                color="fill-blue-500"
              />
            ) : (
              <SaveBlueArrow className="mr-2" color="#FFFFFF" />
            )
          }
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
