import React, { useState, useEffect, useRef, ReactNode } from 'react';

type VirtualizedListProps<T> = {
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
};

type VisibleRange = {
  start: number;
  end: number;
};

const VirtualizedList = <T,>({
  items,
  renderItem,
}: VirtualizedListProps<T>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [visibleRange, setVisibleRange] = useState<VisibleRange>({
    start: 0,
    end: 10,
  });

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
        const buffer = 5;

        // Определение видимого диапазона
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setVisibleRange((prev) => ({
            start: prev.start,
            end: Math.min(items.length, prev.end + buffer),
          }));
        }
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [items.length]);

  return (
    <div
      ref={containerRef}
      style={{ overflowY: 'auto', flex: 1 }}
      className="flex flex-col gap-y-[20px] p-4"
    >
      {items.slice(visibleRange.start, visibleRange.end).map((item, index) => (
        <div
          key={visibleRange.start + index}
          data-index={visibleRange.start + index}
        >
          {renderItem(item, visibleRange.start + index)}
        </div>
      ))}
    </div>
  );
};

export default VirtualizedList;
