import React, { useCallback, useState } from 'react';

import Header from './blocks/header';
import Sidebar from './blocks/sidebar';
import Main from './blocks/main';

const Layout: React.FC = () => {
  const storageValue = window.localStorage.getItem('isSidebarOpen') === 'true';
  const [sidebarOpen, setSidebarOpen] = useState(storageValue);

  const handleSideBarChange = useCallback(() => {
    window.localStorage.setItem('isSidebarOpen', `${!sidebarOpen}`);
    setSidebarOpen(!sidebarOpen);
  }, [sidebarOpen]);

  return (
    <div className="h-screen flex flex-col">
      <Header setSidebarOpen={handleSideBarChange} sidebarOpen={sidebarOpen} />
      <div className="flex flex-1">
        <Sidebar
          setSidebarOpen={handleSideBarChange}
          sidebarOpen={sidebarOpen}
        />
        <Main />
      </div>
    </div>
  );
};

export default Layout;
