const IFRAME_SANDBOX = 'allow-scripts allow-same-origin';

interface IIframe {
  iframeUrl: string;
  isConversionFrame: boolean;
  onLoadIframe?: () => void;
}

const Iframe = ({ iframeUrl, isConversionFrame, onLoadIframe }: IIframe) => {
  const heightLarge =
    'max-h-[calc(100vh_-_60px)] h-[calc(100vh_-_60px)] min-h-[calc(100vh_-_60px)]';
  const heightSmall =
    'small:max-h-[calc(100vh_-_90px)] small:h-[calc(100vh_-_90px)] small:min-h-[calc(100vh_-_90px)]';

  const heightConversionLarge =
    'max-h-[calc(90vh_-_60px)] h-[calc(90vh_-_60px)] min-h-[calc(90vh_-_60px)]';
  const heightConversionSmall =
    'small:max-h-[calc(90vh_-_90px)] small:h-[calc(90vh_-_90px)] small:min-h-[calc(90vh_-_90px)]';

  return (
    <div
      className={`relative w-full ${
        isConversionFrame ? heightConversionLarge : heightLarge
      } ${isConversionFrame ? heightConversionSmall : heightSmall} z-[1]`}
    >
      <iframe
        className={`w-full ${
          isConversionFrame ? heightConversionLarge : heightLarge
        } ${isConversionFrame ? heightConversionSmall : heightSmall} z-[1]`}
        src={iframeUrl}
        width="100%"
        height="100%"
        sandbox={IFRAME_SANDBOX}
        onLoad={onLoadIframe}
      />
    </div>
  );
};

export default Iframe;
